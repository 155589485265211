<template>
  <template v-if="false">
    <Flex justify-center class="pa-6">
      <Loading />
    </Flex>
  </template>
  <template v-else>
    <Chart :options="chartOptions" class="views-chart" />
  </template>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { Chart } from 'highcharts-vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';
import { roundNumber, resolveNumberWithUnit } from '@/common/formatter';

export default defineComponent({
  name: 'EffectiveReachLine',
  components: {
    Chart,
    Flex,
    Loading
  },
  props: {
    data: Object,
    isPeople: Boolean
  },
  setup(props) {
    const isPeople = Boolean(props.isPeople);

    // seriesの(y軸)最大値を算出
    const maxArray = props.data?.lineData.map(e =>
      Math.max.apply(
        null,
        e.data.map(d => (isPeople ? d.people : d.rate))
      )
    );
    const maxPlot = Math.max(...maxArray);
    const maxTarget = isPeople
      ? props.data?.targetPeople
      : props.data?.targetRate;
    // Target(目標値)の方が大きい場合はy軸最大値を指定する
    const yMaxVal = maxTarget > maxPlot ? maxTarget : null;

    const chartOptions = ref({
      chart: {
        type: 'line',
        marginTop: 28,
        scrollablePlotArea: {
          minWidth: 945,
          scrollPositionX: 1
        }
      },
      title: {
        text: null
      },
      legend: {
        enabled: true
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          const yValue = isPeople
            ? resolveNumberWithUnit(this.y, 3, '人')
            : roundNumber(this.y, 2) + ' %';
          const xValue = roundNumber(this.x) + ' GRP';
          return yValue + '<br/>' + xValue;
        }
      },
      xAxis: {
        title: {
          align: 'high',
          offset: 0,
          text: '出稿量(GRP)',
          rotation: 0,
          x: 0,
          y: 32,
          style: {
            fontSize: 10
          }
        },
        min: 0,
        labels: {
          formatter: function(label) {
            return label.value;
          }
        }
      },
      yAxis: {
        title: {
          align: 'high',
          offset: 0,
          text: isPeople ? '(万人)' : '(%)',
          rotation: 0,
          x: -8,
          y: -16,
          style: {
            fontSize: 10
          }
        },
        min: 0,
        max: yMaxVal,
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        labels: {
          formatter: function(label) {
            return isPeople ? label.value / 10000 : label.value;
          }
        },
        plotLines:
          props.data?.targetRate > 0
            ? [
                {
                  dashStyle: 'shortdash',
                  width: 2,
                  label: {
                    align: 'right',
                    x: -8,
                    text: isPeople
                      ? '目標リーチ人数: ' +
                        resolveNumberWithUnit(props.data?.targetPeople, 3, '人')
                      : '目標リーチ率: ' + props.data?.targetRate + '%'
                  },
                  value: isPeople
                    ? props.data?.targetPeople
                    : props.data?.targetRate
                }
              ]
            : []
      },
      series: props.data?.lineData.map(e => ({
        name: e.name,
        data: e.data.map(d => [d.grp, isPeople ? d.people : d.rate]),
        color: e.color
      })),
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });

    return {
      chartOptions
    };
  }
});
</script>
<style lang="scss" scoped>
.views-chart {
  max-width: 100vw;
}
</style>
