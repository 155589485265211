<template>
  <div v-if="seriesData.length" class="station-heatmap">
    <Flex v-if="caption" justify-end>
      <Typography size="s" color="grey" class="mb-2">
        {{ caption }}
      </Typography>
    </Flex>
    <Flex flex-direction="column">
      <Box :margin-left="`${plotLeft}px`">
        <Flex>
          <Flex
            v-for="station in stationNames"
            :key="station.label"
            justify-center
            align-center
            class="station-header"
            :style="{ borderTop: `2px solid ${station.color}` }"
          >
            <Typography size="xs" bold>
              <span :style="{ color: station.color }">●</span>
              {{ station.label }}
            </Typography>
          </Flex>
        </Flex>
      </Box>
      <Chart :options="chartOptions" :callback="chartCallback" />
      <Flex class="heatmap-legends" justify-center>
        <div
          v-for="(data, i) in dataClasses"
          :key="i"
          :class="`legend-level-${i}`"
          :style="{ '--bg-color': data.color }"
        >
          {{ roundNumber(data.from, 2) }}
        </div>
        <div>{{ roundNumber(max, 2) }}</div>
      </Flex>
    </Flex>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import Heatmap from 'highcharts/modules/heatmap';
import Box from '@/components/layout/Box.vue';
import Flex from '@/components/layout/Flex.vue';
import Typography from '@/components/ui/Typography.vue';

import { roundNumber } from '@/common/formatter';

export default defineComponent({
  name: 'TargetRateHeatmap',
  components: {
    Box,
    Chart,
    Flex,
    Typography
  },
  props: {
    seriesData: Object,
    stationNames: Array,
    caption: String
  },
  setup(props) {
    const STATION_COUNT = 5;
    const DIVIDE_NUM = 5;
    const week = ['月', '火', '水', '木', '金', '土', '日'];
    const values = props.seriesData.map(value => value.value);
    const max = Math.max(...values);
    const min = Math.min(...values);
    const level = (max - min) / DIVIDE_NUM;
    // dataClassesを後付の凡例で利用するために変数化
    const dataClasses = [
      {
        from: min,
        to: min + level,
        color: 'var(--contrast-color)'
      },
      {
        from: min + level,
        to: min + level * 2,
        color: 'var(--heatmap-blue-2)'
      },
      {
        from: min + level * 2,
        to: min + level * 3,
        color: 'var(--heatmap-blue-3)'
      },
      {
        from: min + level * 3,
        to: min + level * 4,
        color: 'var(--heatmap-blue-4)'
      },
      {
        from: min + level * 4, // 5段階: 20刻み
        to: max, // value: ターゲット比率
        color: 'var(--heatmap-blue-5)'
      }
    ];
    const chartOptions = ref({
      chart: {
        type: 'heatmap',
        plotBorderWidth: 1,
        borderColor: 'var(--dark-25-hex-color)',
        height: 650,
        marginRight: 0,
        marginTop: 20,
        style: {
          borderColor: 'var(--dark-25-hex-color)'
        }
      },
      labels: {
        align: 'right',
        x: -10,
        y: 0
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
      },
      xAxis: {
        opposite: true,
        categories: [...Array(STATION_COUNT)].reduce(
          list => [...list, ...week],
          []
        ),
        labels: {
          x: 0,
          y: -6
        },
        plotLines: [
          { color: 'var(--dark-60-color)', width: 1, value: 6.5, zIndex: 4 },
          { color: 'var(--dark-60-color)', width: 1, value: 13.5, zIndex: 4 },
          { color: 'var(--dark-60-color)', width: 1, value: 20.5, zIndex: 4 },
          { color: 'var(--dark-60-color)', width: 1, value: 27.5, zIndex: 4 }
        ]
      },
      yAxis: {
        title: null,
        reversed: true,
        categories: [
          '5:00',
          '6:00',
          '7:00',
          '8:00',
          '9:00',
          '10:00',
          '11:00',
          '12:00',
          '13:00',
          '14:00',
          '15:00',
          '16:00',
          '17:00',
          '18:00',
          '19:00',
          '20:00',
          '21:00',
          '22:00',
          '23:00',
          '24:00',
          '25:00',
          '26:00',
          '27:00',
          '28:00'
        ]
      },
      legend: {
        enabled: false
      },
      colorAxis: { dataClasses },
      series: [
        {
          type: 'heatmap',
          data: computed(() => props.seriesData),
          dataLabels: {
            enabled: true,
            formatter: function() {
              return `<span style="color: ${this.point.font}">${this.point.cm}</span>`;
            },
            style: {
              letterSpacing: '-0.6px',
              color: 'var(--primary-color)',
              fontWeight: 'thin'
            }
          }
        }
      ],
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });

    Heatmap(Highcharts);

    const plotLeft = ref(0);
    const chartCallback = data => {
      plotLeft.value = data.plotLeft;
    };

    return {
      roundNumber,
      chartOptions,
      chartCallback,
      plotLeft,
      max,
      dataClasses
    };
  }
});
</script>
<style lang="scss">
.station-header {
  position: relative;
  flex: 1;
  background-color: var(--dark-5-color);
  height: 32px;
  &:not(:last-child) {
    border-right: 1px solid var(--dark-60-color);
    &::before {
      border-right: 1px solid var(--dark-60-color);
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -21px;
    right: -1px;
    background-color: transparent;
    width: 100%;
    height: 22px;
    z-index: 1;
    border-bottom: 1px solid var(--dark-10-color);
    background-size: auto auto;
    background-color: solid var(--dark-10-color);
    background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 14.1%,
      var(--dark-25-color) 14.1%,
      var(--dark-25-color) 14.4%
    );
  }
}

.station-heatmap {
  .highcharts-background {
    fill: transparent;
  }
  .highcharts-plot-border {
    stroke: transparent;
  }
  .highcharts-text-outline {
    stroke: transparent;
  }
  .highcharts-point {
    stroke: var(--dark-10-color);
  }
  .highcharts-plot-band {
    color: var(--dark-60-color);
  }
  .highcharts-grid-line,
  .highcharts-axis-line {
    stroke: transparent;
  }
  .highcharts-axis-labels {
    border: 1px solid var(--dark-60-color);
  }
  .heatmap-legends {
    color: #666666;
    font-size: 11px;
    > * {
      margin-right: 1px;
      font-weight: bold;
    }
    > *:before {
      content: '';
      background-color: var(--bg-color);
      display: block;
      width: 40px;
      height: 12px;
      margin-bottom: 4px;
      margin-left: 1em;
      margin-right: -1em;
    }

    .legend-level {
      &-0:before {
        border: 1px solid var(--dark-10-color);
      }
    }
  }
}
</style>
