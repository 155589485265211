<template>
  <template v-if="false">
    <Flex justify-center class="pa-6">
      <Loading />
    </Flex>
  </template>
  <template v-else>
    <Chart :options="chartOptions" class="views-chart" />
  </template>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { Chart } from 'highcharts-vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';

export default defineComponent({
  name: 'PlacementAmountGraphSov',
  components: {
    Chart,
    Flex,
    Loading
  },
  props: {
    data: Object
  },
  setup(props) {
    // x軸
    const categories = props.data?.grpsForSov?.map(e => {
      const date = new Date(e.stDate);
      return (
        (date.getMonth() + 1).toString() +
        '/' +
        date.getDate().toString() +
        '週'
      );
    });
    // y軸(商品名を抽出して、各商品ごとに配列を作成)
    const products = props.data?.grpsForSov[0].productShare?.map(d => ({
      name: d.productName,
      color: d.color
    }));
    const seriesData = products.map(p => {
      const grps = [];
      const shares = [];
      props.data?.grpsForSov?.forEach(e => {
        const product = e.productShare?.find(d => p.name === d.productName);
        grps.push(product.grp);
        shares.push(product.share);
      });

      return {
        name: p.name,
        grps: grps,
        shares: shares,
        color: p.color
      };
    });

    // highchartではcolumn間のサイズを明示的に指定できない
    // 画面要件として、最低画面画像度 横1280 であるので、
    // これを基準に横1280で表示したときに1画面に5column程度収まるように
    // scrollablePlotArea.minWidthとcolumn幅などを調整する
    const columnNum = categories.length;
    const columnWidth = columnNum <= 5 ? 80 : 88;

    const chartOptions = ref({
      chart: {
        type: 'column',
        marginTop: 28,
        scrollablePlotArea: {
          minWidth: columnWidth * (columnNum * 2 + 1),
          scrollPositionX: 1
        }
      },
      title: {
        text: null
      },
      legend: {
        enabled: true,
        reversed: true
      },
      tooltip: {
        enabled: true,
        pointFormat: '{series.name}: {point.y:.1f} %'
      },
      xAxis: {
        categories: categories
      },
      yAxis: {
        title: {
          align: 'high',
          offset: 0,
          text: '(%)',
          rotation: 0,
          x: -16,
          y: -16,
          style: {
            fontSize: 10
          }
        },
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        min: 0
      },
      plotOptions: {
        column: {
          stacking: 'percent',
          pointWidth: columnWidth
        }
      },
      series: seriesData
        .map(e => ({
          name: e.name,
          data: e.shares,
          color: e.color
        }))
        .reverse(),
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });
    return {
      chartOptions
    };
  }
});
</script>
<style lang="scss" scoped>
.views-chart {
  max-width: 100vw;
}
</style>
