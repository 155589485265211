
import { defineComponent, computed } from 'vue';
import { resolveUnit, resolveNumber } from '@/common/formatter';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useBrandLiftStore } from '@/store/brandLift';
import {
  useEffectiveReach,
  CompareListIds
} from '@/composables/campaign/brand_lift/effectiveReach';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import EffectiveReachLine from '@/pages/campaign/brandlift/EffectiveReachLine.vue';
import SummaryCard from '@/pages/campaign/brandlift/SummaryCard.vue';
import SummaryInfo from '@/pages/campaign/brandlift/SummaryInfo.vue';
import TimeoutScreen from '@/pages/campaign/brandlift/TimeoutScreen.vue';
import Flex from '@/components/layout/Flex.vue';
import Tabs from '@/components/ui/Tabs.vue';
import Radio from '@/components/ui/Radio.vue';
import Box from '@/components/layout/Box.vue';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'EffectiveReach',
  components: {
    Flex,
    Radio,
    Box,
    LoadingMessage,
    SummaryCard,
    SummaryInfo,
    Tabs,
    TimeoutScreen,
    EffectiveReachLine,
    Button
  },
  setup() {
    const route = useRoute();
    const projectId = parseInt(route.params.projectId.toString());
    const store = useBrandLiftStore();

    const {
      summary,
      project,
      area,
      date,
      numOfEffectiveContacts
    } = storeToRefs(store);

    const tabList = computed(() => [
      { id: CompareListIds.past, name: '過去比較' },
      {
        id: CompareListIds.competitive,
        name: '競合比較',
        lock: !store.hasCompetitive,
        showLockIcon: false
      },
      {
        id: CompareListIds.ownCompany,
        name: '自社比較',
        lock: !store.hasOwnCompany,
        showLockIcon: false
      }
    ]);

    const {
      changeTab,
      activeTabId,
      tabListData,
      isPeople,
      initialLoading,
      summariesEffect,
      effectiveReachTimeOut,
      downloadCsv,
      downloadCsvLoading
    } = useEffectiveReach(projectId, tabList.value);

    const graphLoading = computed(() => {
      const activeTabData = tabListData.value.find(
        e => e.id === activeTabId.value
      );
      if (activeTabData === undefined) return false;
      return activeTabData.isLoading;
    });

    return {
      tabList,
      summary,
      activeTabId,
      changeTab,
      summariesEffect,
      project,
      area,
      date,
      numOfEffectiveContacts,
      tabListData,
      isPeople,
      initialLoading,
      effectiveReachTimeOut,
      resolveUnit,
      resolveNumber,
      downloadCsv,
      downloadCsvLoading,
      graphLoading
    };
  }
});
