import { ReachAnalysisApi } from '@/api';
import { AreaInfoIdEnum, ReachAnalysisProject } from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { resolveNumberWithUnit, roundNumber } from '@/common/formatter';
import { validDuration } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { toast } from '@/components/ui/Toast';
import { useBrandLiftStore } from '@/store/brandLift';
import { format } from 'date-fns';
import { Options, SeriesOptionsType } from 'highcharts';
import { storeToRefs } from 'pinia';
import { ref, Ref, watch } from 'vue';
import saveAs from 'file-saver';

export type CompareType = 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY';
type GraphType = 'ratio' | 'hist';

export interface FrequencyDataType {
  isLoading: boolean;
  histOptions: Options;
  stackedOptions: Options;
}

const COMPARE_TYPES: Array<CompareType> = [
  'PAST',
  'COMPETITIVE',
  'OWN_COMPANY'
];
const COLORS = new Map<CompareType, Array<string>>([
  [
    'PAST',
    [
      'var(--dark-10-hex-color)',
      'var(--red-blue_blue-1)',
      'var(--red-blue_blue-2)',
      'var(--red-blue_blue-4)',
      'var(--chart-blue-1)'
    ]
  ],
  [
    'COMPETITIVE',
    [
      'var(--dark-10-hex-color)',
      'var(--red-blue_blue-1)',
      'var(--red-blue_blue-2)',
      'var(--red-blue_blue-4)',
      'var(--chart-blue-1)'
    ]
  ],
  [
    'OWN_COMPANY',
    [
      'var(--dark-10-hex-color)',
      'var(--red-blue_blue-1)',
      'var(--red-blue_blue-2)',
      'var(--red-blue_blue-4)',
      'var(--chart-blue-1)'
    ]
  ]
]);
const HIST_OPTIONS: Options = {
  yAxis: {
    min: 0,
    title: {
      align: 'high',
      offset: 0,
      text: '(万人)',
      rotation: 0,
      x: -8,
      y: -16
    }
  },
  tooltip: {
    enabled: true,
    formatter: function() {
      return resolveNumberWithUnit(this.y * 10000, 3, '人');
    }
  },
  plotOptions: {
    column: {
      stacking: undefined,
      pointPadding: 0.2,
      borderWidth: 0
    }
  },
  legend: {
    enabled: true
  }
};
const STACKED_OPTIONS: Options = {
  yAxis: {
    max: 100,
    min: 0,
    title: {
      align: 'high',
      offset: 0,
      text: '(%)',
      rotation: 0,
      x: -16,
      y: -16
    },
    labels: {}
  },
  tooltip: {
    enabled: true,
    formatter: function() {
      return roundNumber(this.y, 2) + ' %';
    }
  },
  plotOptions: {
    column: {
      stacking: 'normal'
    }
  },
  legend: {
    enabled: false
  }
};
const DEFAULT_OPTIONS: Options = {
  chart: {
    type: 'column',
    marginTop: 28
  },
  title: {
    text: undefined
  },
  xAxis: {
    crosshair: true
  },
  credits: {
    enabled: false
  },
  exporting: {
    buttons: undefined
  }
};

interface UseFrequencyReturnType {
  frequencyData: Ref<Map<CompareType, FrequencyDataType>>;
  compareType: Ref<CompareType>;
  graphType: Ref<GraphType>;
  thresholds: Ref<Array<string>>;
  reachRate: Ref<number | undefined>;
  onChangeCompareType: (_compareType: CompareType) => void;
  initialLoading: Ref<boolean>;
  downloadCsv: () => Promise<void>;
  downloadCsvLoading: Ref<boolean>;
}

interface UseFrequencyThresholdsReturnType {
  onClickEdit: () => void;
  onClickDone: () => Promise<void>;
  editing: Ref<boolean>;
  errorMessage: Ref<string>;
  color: Ref<Array<string> | undefined>;
  thresholds: Ref<Array<string>>;
  editThresholds: Ref<Array<string>>;
  thresholdStrings: Array<string>;
}

const frequencyData = ref<Map<CompareType, FrequencyDataType>>(
  new Map<CompareType, FrequencyDataType>(
    COMPARE_TYPES.map(compareType => [
      compareType,
      {
        isLoading: true,
        histOptions: {},
        stackedOptions: {}
      }
    ])
  )
);
const reachRate = ref<number | undefined>(undefined);
const thresholds = ref<Array<string>>(['-1', '0', '3', '6', '9']);
const color = ref<Array<string> | undefined>(COLORS.get('PAST'));
const initialLoading = ref<boolean>(false);

const fetch = async (
  compareType: CompareType,
  project: ReachAnalysisProject,
  date: DateRange,
  area: AreaInfoIdEnum,
  numOfEffectiveContacts: number
) => {
  const errorToast = () =>
    toast({
      title: '平均フリークエンシーの取得に失敗しました',
      variant: 'error'
    });
  try {
    const result = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequency(
      project.basicInfo?.reachAnalysisProjectId ?? 0,
      numOfEffectiveContacts,
      format(date.start ?? 0, DATE_FORMAT),
      format(date.end ?? 0, DATE_FORMAT),
      area ?? AreaInfoIdEnum.Kanto,
      compareType,
      thresholds.value.map(value => Number(value) + 1)
    );

    if (result.status < 200 || 300 <= result.status) errorToast();
    reachRate.value = result.data.reachRate;
    const stackedSeries: Array<SeriesOptionsType> = thresholds.value.map(
      (threshold, index) => {
        const th: number = Number(threshold) + 1;
        return {
          name: th.toString(),
          index: index,
          data:
            result.data.frequencies?.map(frequency => {
              const value = frequency.averageFrequencies?.find(
                av => av.fqThreshold === th
              );
              return value?.frequencyRate ?? 0;
            }) ?? []
        } as SeriesOptionsType;
      }
    );
    const histSeries: Array<SeriesOptionsType> =
      result.data.frequencies?.map((frequency, index) => {
        return {
          name: frequency.displayName ?? '',
          legendIndex: index,
          index: index,
          data:
            frequency.averageFrequencies?.map(value =>
              value.targetViewNumber ? value.targetViewNumber / 10000 : 0
            ) ?? []
        } as SeriesOptionsType;
      }) ?? [];
    const color = COLORS.get(compareType);
    const histColor = color ? color.slice(1, 5) : [];
    histColor.reverse();
    frequencyData.value.set(compareType, {
      isLoading: false,
      histOptions: {
        ...DEFAULT_OPTIONS,
        ...HIST_OPTIONS,
        series: histSeries,
        xAxis: {
          categories: thresholds.value.map((value, index, array) => {
            const v = Number(value);
            if (v === -1) return '0回';
            else if (v === 9) return '10回以上';
            else return `${Number(array[index]) + 1} 〜 ${array[index + 1]} 回`;
          })
        },
        colors: histColor
      },
      stackedOptions: {
        ...DEFAULT_OPTIONS,
        ...STACKED_OPTIONS,
        series: stackedSeries,
        xAxis: {
          categories:
            result.data.frequencies?.map(value => value.displayName ?? '') ?? []
        },
        colors: color
      }
    });
  } catch (e) {
    errorToast();
  }
};

const fetchAllSequentially = async (
  project: ReachAnalysisProject,
  date: DateRange,
  area: AreaInfoIdEnum,
  numOfEffectiveContacts: number,
  initial = false
) => {
  COMPARE_TYPES.forEach(compareType =>
    frequencyData.value.set(compareType, {
      isLoading: true,
      histOptions: {},
      stackedOptions: {}
    })
  );
  for (const compareType of COMPARE_TYPES) {
    if (initial && compareType === 'PAST') {
      initialLoading.value = true;
    }
    await fetch(compareType, project, date, area, numOfEffectiveContacts);
    if (initial && compareType === 'PAST') {
      initialLoading.value = false;
    }
  }
};

export const useFrequency = (): UseFrequencyReturnType => {
  const brandLiftStore = useBrandLiftStore();
  const { project, area, date, numOfEffectiveContacts } = storeToRefs(
    brandLiftStore
  );
  const compareType = ref<CompareType>('PAST');
  const graphType = ref<GraphType>('ratio');

  const onChangeCompareType = (_compareType: CompareType) => {
    compareType.value = _compareType;
    color.value = COLORS.get(_compareType);
  };

  const Summary = () => {
    if (
      project.value &&
      date.value &&
      area.value &&
      numOfEffectiveContacts.value &&
      validDuration(date.value)
    ) {
      fetchAllSequentially(
        project.value,
        date.value,
        area.value,
        parseInt(numOfEffectiveContacts.value),
        true
      ).then();
    }
  };
  Summary();

  watch([project, area, date, numOfEffectiveContacts], Summary);

  const downloadCsv = async () => {
    downloadCsvLoading.value = true;

    if (
      project.value &&
      date.value &&
      area.value &&
      numOfEffectiveContacts.value &&
      validDuration(date.value)
    ) {
      const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdAverageFrequencyCsv(
        project.value.basicInfo?.reachAnalysisProjectId ?? 0,
        parseInt(numOfEffectiveContacts.value),
        format(date.value.start ?? 0, DATE_FORMAT),
        format(date.value.end ?? 0, DATE_FORMAT),
        area.value ?? AreaInfoIdEnum.Kanto,
        compareType.value,
        thresholds.value.map(value => Number(value) + 1)
      );

      const mineType = res.headers['content-type'];
      const name = res.headers['content-disposition'];
      const reg = RegExp(/filename=(.*)/);
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
        type: mineType
      });
      saveAs(blob, name.match(reg)[1]);
    }

    downloadCsvLoading.value = false;
  };

  const downloadCsvLoading = ref<boolean>(false);

  return {
    frequencyData,
    graphType,
    compareType,
    thresholds,
    reachRate,
    onChangeCompareType,
    initialLoading,
    downloadCsv,
    downloadCsvLoading
  };
};

export const useFrequencyThresholds = (): UseFrequencyThresholdsReturnType => {
  const brandLiftStore = useBrandLiftStore();
  const editing = ref(false);
  const errorMessage = ref('');
  const clickable = ref(true);

  const editThresholds = ref<Array<string>>(
    thresholds.value.map(value => value)
  );

  watch(thresholds, value => {
    clickable.value = value.every((value, index, array) => {
      return Array.length === index + 1 ?? array[index] < array[index + 1];
    });
    if (!clickable.value)
      errorMessage.value =
        '1-9の数字を左側から順に大きくなるように入力してください';
  });

  const onClickDone = async () => {
    thresholds.value = editThresholds.value;
    editing.value = false;
    errorMessage.value = '';
    if (
      brandLiftStore.project &&
      brandLiftStore.date &&
      brandLiftStore.area &&
      brandLiftStore.numOfEffectiveContacts &&
      validDuration(brandLiftStore.date)
    ) {
      fetchAllSequentially(
        brandLiftStore.project,
        brandLiftStore.date,
        brandLiftStore.area,
        parseInt(brandLiftStore.numOfEffectiveContacts)
      ).then();
    }
  };

  const onClickEdit = () => (editing.value = true);

  return {
    editing,
    onClickEdit,
    onClickDone,
    thresholds,
    editThresholds,
    errorMessage,
    color,
    thresholdStrings: thresholds.value.map((value, index, array) => {
      const v = Number(value);
      if (v === -1) return '0回';
      else if (v === 9) return '10回以上';
      else return `${Number(array[index]) + 1} 〜 ${array[index + 1]} 回`;
    })
  };
};

export const resetThresholds = (): void => {
  thresholds.value = ['-1', '0', '3', '6', '9'];
};

interface UseValidatorReturnType {
  validNumber: (value: string, maxVal: number, minval: number) => string;
}

export const useValidator = (): UseValidatorReturnType => {
  const validNumber = (value: string, maxVal: number, minVal: number) => {
    const num = Number(value);
    if (value === '') {
      return '必須';
    }
    if (num < 0) {
      return '正しくありません。';
    }
    if (num < minVal) {
      return `最低限 ${minVal}`;
    }
    if (num > maxVal) {
      return `最大 ${maxVal}`;
    }
    if (!Number.isInteger(num)) {
      return `小数値が含まれています`;
    }
    return '';
  };
  return {
    validNumber
  };
};
