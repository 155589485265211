<template>
  <template v-if="false">
    <Flex justify-center class="pa-6">
      <Loading />
    </Flex>
  </template>
  <template v-else>
    <Flex justify-center align-center>
      <Chart :options="chartOptions" class="station-donut" />
    </Flex>
  </template>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
import { Chart } from 'highcharts-vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';

export default defineComponent({
  name: 'TargetRateDonut',
  components: {
    Chart,
    Flex,
    Loading
  },
  props: {
    data: Array
  },
  setup(props) {
    const chartOptions = ref({
      chart: {
        type: 'pie',
        width: 450,
        height: 206,
        margin: 0,
        spacing: 15
      },
      title: {
        style: { fontSize: 12, fontFamily: 'var(--font-family)' },
        y: 108,
        text: 'CM出稿本数'
      },
      tooltip: {
        formatter: function() {
          return this.key;
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            distance: -23,
            style: {
              fontWeight: 700,
              fontSize: 12
            }
          },
          showInLegend: true
        }
      },
      legend: {
        layout: 'vertical',
        itemStyle: {
          fontWeight: 'normal'
        },
        itemMarginBottom: 8,
        align: 'right',
        verticalAlign: 'middle',
        floating: true,
        x: 0,
        y: 0
      },
      series: [
        {
          name: 'CM出稿本数',
          data: computed(() => props.data),
          size: '100%',
          innerSize: '55%',
          id: 'versions'
        }
      ],
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });

    return {
      chartOptions
    };
  }
});
</script>
<style lang="scss">
.station-donut {
  .highcharts-text-outline {
    stroke: transparent;
  }
}
</style>
