
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useBrandLiftStore } from '@/store/brandLift';
import { resolveNumber, resolveUnit } from '@/common/formatter';
import { useViews, ViewListIds } from '@/composables/campaign/brand_lift/views';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import ViewsColum from '@/pages/campaign/brandlift/ViewsColum.vue';
import SummaryCard from '@/pages/campaign/brandlift/SummaryCard.vue';
import SummaryInfo from '@/pages/campaign/brandlift/SummaryInfo.vue';
import Flex from '@/components/layout/Flex.vue';
import Tabs from '@/components/ui/Tabs.vue';
import TimeoutScreen from '@/pages/campaign/brandlift/TimeoutScreen.vue';

export default defineComponent({
  name: 'Views',
  components: {
    Flex,
    LoadingMessage,
    SummaryCard,
    SummaryInfo,
    Tabs,
    TimeoutScreen,
    ViewsColum
  },
  setup() {
    const route = useRoute();
    const projectId = Number(route.params.projectId);
    const store = useBrandLiftStore();
    const {
      summary,
      project,
      area,
      date,
      numOfEffectiveContacts
    } = storeToRefs(store);
    const tabList = [
      { id: ViewListIds.target, name: 'ターゲット視聴回数' },
      { id: ViewListIds.total, name: '延べ視聴回数' }
    ];
    const {
      activeTabId,
      changeTab,
      summariesEffect,
      tabListData,
      viewsTimeOut,
      initialLoading
    } = useViews(projectId, tabList);

    return {
      tabList,
      summary,
      activeTabId,
      changeTab,
      summariesEffect,
      initialLoading,
      viewsTimeOut,
      project,
      area,
      date,
      numOfEffectiveContacts,
      tabListData,
      ViewListIds,
      resolveUnit,
      resolveNumber
    };
  }
});
