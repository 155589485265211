import { ReachAnalysisApi } from '@/api';
import { EffectiveReach, ReachAnalysisProject } from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { validDuration } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { useTabs } from '@/composables/tabs';
import { useBrandLiftStore } from '@/store/brandLift';
import Axios from 'axios';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, ref, watch } from 'vue';
import saveAs from 'file-saver';

export const CompareListIds = {
  past: 'PAST',
  competitive: 'COMPETITIVE',
  ownCompany: 'OWN_COMPANY'
} as const;
export type CompareListType = typeof CompareListIds;
export type CompareType = CompareListType[keyof CompareListType];

// APIが変更になった場合を考慮してフロント用の構造を定義
interface EffectiveReachGraphDataType {
  targetPeople?: number;
  targetRate?: number;
  lineData: {
    name?: string;
    data: { grp?: number; people?: number; rate?: number }[];
    color: string;
  }[];
}

interface UseEffectiveReachReturnType {
  changeTab: (tabId: string) => Promise<void>;
  summariesEffect: (
    params: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => void;
  activeTabId: Ref<string>;
  tabListData: Ref<
    {
      id: string;
      graphData: EffectiveReachGraphDataType | undefined;
      isLoading: boolean;
    }[]
  >;
  isPeople: Ref<boolean>;
  effectiveReachTimeOut: Ref<boolean>;
  initialLoading: Ref<boolean>;
  downloadCsv: () => Promise<void>;
  downloadCsvLoading: Ref<boolean>;
}

export const useEffectiveReach = (
  reachAnalysisProjectId: number,
  tabList: { id: string; name: string }[]
): UseEffectiveReachReturnType => {
  const { activeTabId, changeTab: _changeTab } = useTabs(tabList[0].id);
  const store = useBrandLiftStore();
  const { project, area, date, numOfEffectiveContacts } = storeToRefs(store);
  const tabListData = ref<
    {
      id: string;
      graphData: EffectiveReachGraphDataType | undefined;
      isLoading: boolean;
    }[]
  >([]);
  tabListData.value = tabList.map(data => ({
    id: data.id,
    graphData: undefined,
    isLoading: false
  }));
  const isPeople = ref<boolean>(true);
  const currentCompareType = ref<CompareType>(CompareListIds.past);
  const effectiveReachTimeOut = ref<boolean>(false);
  const initialLoading = ref<boolean>(false);

  const setData = (response: EffectiveReach | undefined, type: CompareType) => {
    const colors = (function(type) {
      if (type === CompareListIds.competitive) {
        return [
          'var(--chart-color-1)',
          'var(--chart-color-2)',
          'var(--chart-color-3',
          'var(--chart-color-4)'
        ];
      }
      if (type === CompareListIds.ownCompany) {
        return [
          'var(--chart-color-1)',
          'var(--chart-color-2)',
          'var(--chart-color-3',
          'var(--chart-color-4)'
        ];
      }
      return [
        'var(--chart-color-1)',
        'var(--chart-color-2)',
        'var(--chart-color-3',
        'var(--chart-color-4)'
      ];
    })(type);
    let colorIndex = 0;
    return {
      targetPeople: response?.targetReachNumberOfPeople,
      targetRate: response?.targetEffectiveReachRate,
      lineData:
        response?.effectiveReachs?.map(e => ({
          name: e.displayName ?? '',
          data: e.numbersOfReachCurve
            ? e.numbersOfReachCurve.map(d => ({
                grp: d.grp,
                people: d.numberOfPeople,
                rate: d.rate
              }))
            : [],
          color: colors[colorIndex++]
        })) || []
    };
  };

  const fetch = async () => {
    const today = new Date();
    const effectiveNumberOfViews = numOfEffectiveContacts.value
      ? parseInt(numOfEffectiveContacts.value, 10)
      : undefined;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);
    const areaCode = area.value;
    const compareType = currentCompareType.value;
    if (effectiveNumberOfViews && areaCode) {
      return await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReach(
        reachAnalysisProjectId,
        effectiveNumberOfViews,
        startData,
        endData,
        areaCode,
        compareType
      );
    }
  };

  const changeTab = async (tabId: string) => {
    currentCompareType.value = (function(id) {
      if (tabList[1].id === id) {
        return CompareListIds.competitive;
      }
      if (tabList[2].id === id) {
        return CompareListIds.ownCompany;
      }
      return CompareListIds.past;
    })(tabId);

    _changeTab(tabId);

    const num = tabListData.value.findIndex(data => data.id == tabId);
    tabListData.value[num].isLoading = true;
    try {
      const result = await fetch();
      tabListData.value[num].graphData = setData(
        result?.data,
        currentCompareType.value
      );
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
        effectiveReachTimeOut.value = true;
      }
    }
    tabListData.value[num].isLoading = false;
  };

  const summariesEffect = async (
    values: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => {
    const [project, area] = values;
    if (
      project &&
      area &&
      date.value &&
      numOfEffectiveContacts.value &&
      validDuration(date.value)
    ) {
      const num = tabListData.value.findIndex(
        data => data.id == currentCompareType.value
      );
      tabListData.value[num].isLoading = true;

      initialLoading.value = true;
      effectiveReachTimeOut.value = false;
      try {
        const result = await fetch();
        tabListData.value[num].graphData = setData(
          result?.data,
          currentCompareType.value
        );
      } catch (e) {
        if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
          effectiveReachTimeOut.value = true;
        }
      }

      initialLoading.value = false;
      tabListData.value[num].isLoading = false;
    }
  };
  watch([project, area, date, numOfEffectiveContacts], summariesEffect);

  const downloadCsv = async () => {
    downloadCsvLoading.value = true;

    const today = new Date();
    const effectiveNumberOfViews = numOfEffectiveContacts.value
      ? parseInt(numOfEffectiveContacts.value, 10)
      : undefined;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);
    const areaCode = area.value;
    const compareType = currentCompareType.value;

    if (effectiveNumberOfViews && areaCode) {
      const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdEffectiveReachCsv(
        reachAnalysisProjectId,
        effectiveNumberOfViews,
        startData,
        endData,
        areaCode,
        compareType
      );
      const mineType = res.headers['content-type'];
      const name = res.headers['content-disposition'];
      const reg = RegExp(/filename=(.*)/);
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
        type: mineType
      });
      saveAs(blob, name.match(reg)[1]);
    }

    downloadCsvLoading.value = false;
  };

  const downloadCsvLoading = ref<boolean>(false);

  return {
    changeTab,
    summariesEffect,
    activeTabId,
    tabListData,
    isPeople,
    effectiveReachTimeOut,
    initialLoading,
    downloadCsv,
    downloadCsvLoading
  };
};
