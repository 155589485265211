
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Card from '@/components/ui/Card.vue';
import Typography from '@/components/ui/Typography.vue';

export default defineComponent({
  name: 'SummaryCard',
  components: { Card, Flex, Typography },
  props: {
    title: String
  }
});
