<template>
  <template v-if="false">
    <Flex justify-center class="pa-6">
      <Loading />
    </Flex>
  </template>
  <template v-else>
    <Chart :options="chartOptions" class="views-chart" />
  </template>
</template>
<script>
import { defineComponent, ref } from 'vue';
import { Chart } from 'highcharts-vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';
import { roundNumber } from '@/common/formatter';

export default defineComponent({
  name: 'PlacementAmountGraph',
  components: {
    Chart,
    Flex,
    Loading
  },
  props: {
    data: Object,
    isAccumulation: Boolean
  },
  setup(props) {
    const isAccumulation = Boolean(props.isAccumulation);

    const chartOptions = ref({
      chart: {
        type: isAccumulation ? 'line' : 'column',
        marginTop: 28,
        scrollablePlotArea: {
          minWidth: 945,
          scrollPositionX: 1
        }
      },
      title: {
        text: null
      },
      legend: {
        enabled: true
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          const yValue = roundNumber(this.y) + ' GRP';
          const xValue =
            props.data?.accumulation[this.series.index].numbersOfGrp[this.x]
              .date;
          return xValue + '<br/>' + yValue;
        }
      },
      xAxis: {
        labels: {
          formatter: function(label) {
            return label.pos + 1 + '日目';
          },
          step: 1,
          rotation: -90
        }
      },
      yAxis: {
        title: null,
        gridLineDashStyle: 'Dash',
        gridLineWidth: 1,
        labels: {
          formatter: function(label) {
            return label.value;
          }
        }
      },
      series: isAccumulation
        ? props.data?.accumulation.map(e => ({
            name: e.displayName,
            data: e.numbersOfGrp.map(d => d.grp),
            color: e.color
          }))
        : props.data?.day.map(e => ({
            name: e.displayName,
            data: e.numbersOfGrp.map(d => d.grp),
            color: e.color
          })),
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });
    return {
      chartOptions
    };
  }
});
</script>
<style lang="scss" scoped>
.views-chart {
  max-width: 100vw;
}
</style>
