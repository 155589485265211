
import { computed, defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStrategy } from '@/composables/campaign/brand_lift/strategy';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import HeatmapByStation from '@/pages/campaign/brandlift/HeatmapByStation.vue';
import Flex from '@/components/layout/Flex.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import Card from '@/components/ui/Card.vue';
import DataList from '@/components/ui/DataList.vue';
import Tabs from '@/components/ui/Tabs.vue';
import Typography from '@/components/ui/Typography.vue';
import TimeoutScreen from '@/pages/campaign/brandlift/TimeoutScreen.vue';
import { storeToRefs } from 'pinia';
import { useBrandLiftStore } from '@/store/brandLift';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'Strategy',
  components: {
    BaseButton,
    Card,
    DataList,
    Flex,
    HeatmapByStation,
    LoadingMessage,
    Tabs,
    TimeoutScreen,
    Typography,
    Button
  },
  setup() {
    const route = useRoute();
    const projectId = Number(route.params.projectId);

    const tabList = [
      { id: 'viewingRate', name: 'CM未接触者 視聴率' },
      { id: 'contentRatio', name: 'CM未接触者 含有率' }
    ];

    const headers = (type: 'viewingRate' | 'contentRatio') => {
      const label =
        type === 'viewingRate'
          ? 'CM未接触者 視聴率(％)'
          : 'CM未接触者 含有率(％)';
      return [
        { id: 'rank', label: '順位' },
        { id: 'proportion', label: label, width: '170px' },
        { id: 'program', label: '番組名' },
        { id: 'station', label: '放送局' },
        { id: 'weekday', label: '曜日' },
        { id: 'time', label: '標準放送時間' }
      ];
    };

    const store = useBrandLiftStore();

    const { project, area, date, numOfEffectiveContacts } = storeToRefs(store);

    const {
      changeTab,
      summariesEffect,
      activeTabId,
      isLoadingViewingRateHeatmaps,
      isLoadingContentRatioHeatmaps,
      isLoadingViewingRateList,
      isLoadingContentRatioList,
      straregyTimeout,
      viewingRateSeriesData,
      contentRatioSeriesData,
      viewingRateItems,
      contentRatioItems,
      viewingRateItemCount,
      contentRatioItemCount,
      stationNames,
      addCount,
      DATA_LIST_LIMIT,
      downloadCsv,
      downloadCsvLoading
    } = useStrategy(projectId, tabList);

    const graphLoading = computed(() => {
      switch (activeTabId.value) {
        case 'viewingRate':
          return isLoadingViewingRateHeatmaps.value;
        case 'contentRatio':
          return isLoadingContentRatioHeatmaps.value;
        default:
          return false;
      }
    });

    return {
      headers,
      tabList,
      changeTab,
      summariesEffect,
      project,
      area,
      date,
      numOfEffectiveContacts,
      activeTabId,
      straregyTimeout,
      isLoadingViewingRateHeatmaps,
      isLoadingContentRatioHeatmaps,
      isLoadingViewingRateList,
      isLoadingContentRatioList,
      viewingRateSeriesData,
      contentRatioSeriesData,
      viewingRateItems,
      contentRatioItems,
      viewingRateItemCount,
      contentRatioItemCount,
      stationNames,
      addCount,
      DATA_LIST_LIMIT,
      downloadCsv,
      downloadCsvLoading,
      graphLoading
    };
  }
});
