
import { defineComponent } from 'vue';
import FrequencyEditableLegend from '@/pages/campaign/brandlift/FrequencyEditableLegend.vue';
import Flex from '@/components/layout/Flex.vue';
import { Chart } from 'highcharts-vue';

export default defineComponent({
  name: 'FrequencyGraph',
  components: {
    FrequencyEditableLegend,
    Flex,
    Chart
  },
  props: {
    options: {
      type: Object,
      required: true
    },
    graphType: {
      type: String,
      required: true
    }
  },
  setup() {
    return;
  }
});
