import { ReachAnalysisApi } from '@/api';
import {
  AreaInfoIdEnum,
  ReachAnalysisProject,
  ViewsNumbersOfViewing
} from '@/api/openapi';
import { useTabs } from '@/composables/tabs';
import { useBrandLiftStore } from '@/store/brandLift';
import { storeToRefs } from 'pinia';
import { Ref, ref, watch } from 'vue';
import { useStationColor } from './colors';
import { format } from 'date-fns';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { DATE_FORMAT } from '@/common/format';
import { validDuration } from '@/common/validation';
import Axios from 'axios';
import { httpCode } from '@/common/constant';

export const ViewListIds = {
  target: 'TARGET',
  total: 'TOTAL'
} as const;
export type ViewListType = typeof ViewListIds;
export type ViewType = ViewListType[keyof ViewListType];

interface UseViewsReturnType {
  changeTab: (tabId: string) => Promise<void>;
  summariesEffect: (
    params: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => void;
  activeTabId: Ref<string>;
  viewsTimeOut: Ref<boolean>;
  initialLoading: Ref<boolean>;
  tabListData: Ref<
    {
      id: string;
      graphData: { name: string; y: number }[] | undefined;
      isLoading: boolean;
    }[]
  >;
}

export const useViews = (
  reachAnalysisProjectId: number,
  tabList: { id: string; name: string }[]
): UseViewsReturnType => {
  const { activeTabId, changeTab: _changeTab } = useTabs(tabList[0].id);
  const { getStationColorByCode } = useStationColor();
  const store = useBrandLiftStore();
  const { project, area, date, numOfEffectiveContacts } = storeToRefs(store);
  const viewsTimeOut = ref(false);
  const initialLoading = ref(false);
  const tabListData = ref<
    {
      id: string;
      graphData: { name: string; y: number }[] | undefined;
      isLoading: boolean;
    }[]
  >([]);
  tabListData.value = tabList.map(data => ({
    id: data.id,
    graphData: undefined,
    isLoading: false
  }));
  const currentViewType = ref<ViewType>(ViewListIds.target);

  const setData = (numbersOfViewing?: ViewsNumbersOfViewing[]) =>
    numbersOfViewing?.map(viewing => ({
      name: viewing.stationName ?? '',
      y: viewing.numberOfViewing ?? 0,
      color: getStationColorByCode(viewing.stationCode)
    })) || [];

  const fetch = async () => {
    const today = new Date();
    const effectiveNumberOfViews = numOfEffectiveContacts.value
      ? numOfEffectiveContacts.value
      : undefined;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);
    const areaCode = area.value;
    const viewType = currentViewType.value;
    if (effectiveNumberOfViews) {
      return await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdViews(
        reachAnalysisProjectId,
        parseInt(effectiveNumberOfViews),
        startData,
        endData,
        areaCode ?? AreaInfoIdEnum.Kanto,
        viewType
      );
    }
  };

  const changeTab = async (tabId: string) => {
    currentViewType.value =
      tabId === tabList[0].id ? ViewListIds.target : ViewListIds.total;
    _changeTab(tabId);

    const num = tabListData.value.findIndex(data => data.id == tabId);
    tabListData.value[num].isLoading = true;
    try {
      const result = await fetch();
      tabListData.value[num].graphData = setData(result?.data.numbersOfViewing);
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
        viewsTimeOut.value = true;
      }
    }

    tabListData.value[num].isLoading = false;
  };

  const summariesEffect = async (
    values: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => {
    const [project, area] = values;
    if (
      project &&
      area &&
      date.value &&
      numOfEffectiveContacts.value &&
      validDuration(date.value)
    ) {
      const num = tabListData.value.findIndex(
        data => data.id == currentViewType.value
      );
      tabListData.value[num].isLoading = true;
      viewsTimeOut.value = false;
      initialLoading.value = true;
      try {
        const result = await fetch();
        tabListData.value[num].graphData = setData(
          result?.data.numbersOfViewing
        );
      } catch (e) {
        if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
          viewsTimeOut.value = true;
        }
      }
      initialLoading.value = false;
      tabListData.value[num].isLoading = false;
    }
  };
  watch([project, area, date, numOfEffectiveContacts], summariesEffect);

  return {
    changeTab,
    initialLoading,
    viewsTimeOut,
    summariesEffect,
    activeTabId,
    tabListData
  };
};
