import { ReachAnalysisApi } from '@/api';
import {
  AdditionalPlacementHeatMap,
  ProgramEvaluationsProgramRanking,
  ReachAnalysisProject
} from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { roundNumber } from '@/common/formatter';
import { validDuration } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { useTabs } from '@/composables/tabs';
import { useBrandLiftStore } from '@/store/brandLift';
import Axios from 'axios';
import { format } from 'date-fns';
import saveAs from 'file-saver';
import { storeToRefs } from 'pinia';
import { Ref, ref, watch } from 'vue';
import { useStationColor } from './colors';

const DATA_LIST_LIMIT = 20;
const today = new Date();

type SeriesData = {
  x: number;
  y: number;
  cm: string;
  value: number;
  font: string;
};

type DataListItem = {
  id: number;
  rank: number;
  proportion: string;
  program: string;
  station: { name: string; color: string };
  weekday: string;
  time: string;
};

interface UseStrategyReturnType {
  changeTab: (tabId: string) => Promise<void>;
  addCount: (viewType: 'VIEWING_RATE' | 'CONTENT_RATE') => void;
  summariesEffect: (
    params: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => void;
  activeTabId: Ref<string>;
  isLoadingViewingRateHeatmaps: Ref<boolean>;
  isLoadingContentRatioHeatmaps: Ref<boolean>;
  isLoadingViewingRateList: Ref<boolean>;
  isLoadingContentRatioList: Ref<boolean>;
  viewingRateSeriesData: Ref<SeriesData[]>;
  contentRatioSeriesData: Ref<SeriesData[]>;
  viewingRateItems: Ref<DataListItem[]>;
  contentRatioItems: Ref<DataListItem[]>;
  viewingRateItemCount: Ref<number>;
  contentRatioItemCount: Ref<number>;
  stationNames: Ref<{ label: string; color: string }[]>;
  DATA_LIST_LIMIT: number;
  straregyTimeout: Ref<boolean>;
  downloadCsv: () => Promise<void>;
  downloadCsvLoading: Ref<boolean>;
}

export const useStrategy = (
  reachAnalysisProjectId: number,
  tabList: { id: string; name: string }[]
): UseStrategyReturnType => {
  const DIVIDE_NUM = 5;
  const store = useBrandLiftStore();
  const { project, area, date, numOfEffectiveContacts } = storeToRefs(store);
  const viewingRateSeriesData = ref<SeriesData[]>([]);
  const contentRatioSeriesData = ref<SeriesData[]>([]);
  const viewingRateItems = ref<DataListItem[]>([]);
  const contentRatioItems = ref<DataListItem[]>([]);
  const viewingRateItemCount = ref(1);
  const contentRatioItemCount = ref(1);
  const stationNames = ref<{ label: string; color: string }[]>([]);
  const isLoadingViewingRateHeatmaps = ref(false);
  const isLoadingContentRatioHeatmaps = ref(false);
  const isLoadingViewingRateList = ref(false);
  const isLoadingContentRatioList = ref(false);
  const straregyTimeout = ref(false);
  const { getStationColorByCode } = useStationColor();
  const { activeTabId, changeTab: _changeTab } = useTabs(tabList[0].id);
  const currentViewType = ref<'VIEWING_RATE' | 'CONTENT_RATE'>('VIEWING_RATE');

  const setHeatMapSeriesData = (heatmaps?: AdditionalPlacementHeatMap[]) => {
    if (!heatmaps) return [];
    const values = heatmaps.flatMap(heatmap =>
      heatmap.heatMapByDayOfWeek?.flatMap(week =>
        week.heatMapCells?.map(cell => cell.ratio)
      )
    ) as Array<number>;
    const max = Math.max(...values);
    const min = Math.min(...values);
    const level = (max - min) / DIVIDE_NUM;

    let seriesData: SeriesData[] = [];
    for (const heatmap of heatmaps) {
      if (heatmap.heatMapByDayOfWeek) {
        const stationIndex = heatmaps.findIndex(
          _heatmap => _heatmap.stationName === heatmap.stationName
        );
        for (const day of heatmap.heatMapByDayOfWeek) {
          if (day.heatMapCells) {
            const dayIndex =
              heatmap.heatMapByDayOfWeek?.findIndex(
                _day => _day.dayOfWeek === day.dayOfWeek
              ) ?? 0;
            const cells = day.heatMapCells.map(cell => ({
              x: dayIndex + stationIndex * 7,
              y: cell.broadcastTimezone ? cell.broadcastTimezone - 5 : 0,
              cm:
                cell.numberOfCmPlacement && cell.numberOfCmPlacement > 0
                  ? cell.numberOfCmPlacement.toString()
                  : '', // 出稿数
              value: cell.ratio ?? 0, // CM未接触者視聴率/含有率
              font:
                cell.ratio && cell.ratio > min + level * 2
                  ? 'var(--contrast-color)'
                  : 'var(--primary-color)'
            }));
            seriesData = [...seriesData, ...cells];
          }
        }
      }
    }
    return seriesData;
  };
  const { getStationColorByName } = useStationColor();
  const setListData = (programRanking?: ProgramEvaluationsProgramRanking[]) =>
    programRanking?.map((program, index) => ({
      id: index,
      rank: program.ranking ?? 0,
      proportion: roundNumber(program.ratio ?? 0, 2),
      program: program.programName ?? '',
      station: {
        name: program.stationName ?? '',
        color: program.stationName
          ? getStationColorByName(program.stationName)
          : 'var(--contrast-color)'
      },
      // dayOfWeekは月曜始まりで、1〜7
      weekday: program.dayOfWeek
        ? ['月', '火', '水', '木', '金', '土', '日'][program.dayOfWeek - 1]
        : '',
      time: `${program.standardBroadcastStartTime}-${program.standardBroadcastEndTime}`
    })) || [];

  const fetchHeatmaps = async () => {
    const effectiveNumberOfViews = numOfEffectiveContacts.value;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);
    const areaCode = area.value;
    const viewType = currentViewType.value;
    if (effectiveNumberOfViews && startData && endData && areaCode)
      return await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacement(
        reachAnalysisProjectId,
        parseInt(effectiveNumberOfViews),
        startData,
        endData,
        areaCode,
        viewType
      );
  };

  const fetchList = async () => {
    if (!project.value || !area.value || !date.value) return;
    const effectiveNumberOfViews = numOfEffectiveContacts.value;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);
    const areaCode = area.value;
    const viewType = currentViewType.value;
    if (effectiveNumberOfViews && startData && endData)
      return await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdProgramEvaluation(
        reachAnalysisProjectId,
        parseInt(effectiveNumberOfViews),
        startData,
        endData,
        areaCode,
        viewType
      );
  };

  const changeTab = async (tabId: string) => {
    currentViewType.value =
      tabId === tabList[0].id ? 'VIEWING_RATE' : 'CONTENT_RATE';

    isLoadingContentRatioHeatmaps.value = true;
    straregyTimeout.value = false;
    fetchHeatmaps()
      .then(result => {
        isLoadingContentRatioHeatmaps.value = false;
        if (!result) return;
        contentRatioSeriesData.value = setHeatMapSeriesData(
          result.data.heatMaps
        );
      })
      .catch(e => {
        if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
          straregyTimeout.value = true;
        }
        isLoadingContentRatioHeatmaps.value = false;
      });

    isLoadingContentRatioList.value = true;
    fetchList()
      .then(result => {
        isLoadingContentRatioList.value = false;
        if (result?.data.programRanking) {
          contentRatioItems.value = setListData(result.data.programRanking);
        }
      })
      .catch(e => {
        if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
          straregyTimeout.value = true;
        }
        isLoadingContentRatioList.value = false;
      });

    _changeTab(tabId);
  };

  const addCount = (viewType: 'VIEWING_RATE' | 'CONTENT_RATE') => {
    if (viewType === 'VIEWING_RATE') {
      viewingRateItemCount.value++;
    } else {
      contentRatioItemCount.value++;
    }
  };

  const summariesEffect = async (
    values: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => {
    const [project, area, date, numOfEffectiveContacts] = values;
    if (
      project &&
      area &&
      date &&
      numOfEffectiveContacts &&
      validDuration(date)
    ) {
      // ヒートマップのデータを取得
      isLoadingViewingRateHeatmaps.value = true;
      straregyTimeout.value = false;
      fetchHeatmaps()
        .then(result => {
          isLoadingViewingRateHeatmaps.value = false;
          if (!result) return;
          viewingRateSeriesData.value = setHeatMapSeriesData(
            result.data.heatMaps
          );

          const _stationNames = result.data.heatMaps
            ?.map(heatmap => ({
              label: heatmap.stationName ?? '',
              color: heatmap.stationCode
                ? getStationColorByCode(heatmap.stationCode)
                : ''
            }))
            .filter(station => !!station.label);
          if (_stationNames?.length) {
            stationNames.value = _stationNames;
          }
        })
        .catch(e => {
          if (
            Axios.isAxiosError(e) &&
            e.response?.status === httpCode.timeout
          ) {
            straregyTimeout.value = true;
          }
          isLoadingViewingRateHeatmaps.value = false;
        });

      // 番組評価一覧のデータを取得
      isLoadingViewingRateList.value = true;
      fetchList()
        .then(result => {
          isLoadingViewingRateList.value = false;
          if (result?.data.programRanking) {
            viewingRateItems.value = setListData(result.data.programRanking);
          }
        })
        .catch(e => {
          if (
            Axios.isAxiosError(e) &&
            e.response?.status === httpCode.timeout
          ) {
            straregyTimeout.value = true;
          }
          isLoadingViewingRateList.value = false;
        });
    }
  };

  watch([project, area, date, numOfEffectiveContacts], summariesEffect);

  const downloadCsv = async () => {
    downloadCsvLoading.value = true;

    const today = new Date();
    const effectiveNumberOfViews = numOfEffectiveContacts.value
      ? parseInt(numOfEffectiveContacts.value, 10)
      : undefined;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);

    if (effectiveNumberOfViews && area.value) {
      const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdAdditionalPlacementCsv(
        reachAnalysisProjectId,
        effectiveNumberOfViews,
        startData,
        endData,
        area.value
      );

      const mineType = res.headers['content-type'];
      const name = res.headers['content-disposition'];
      const reg = RegExp(/filename=(.*)/);
      const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
        type: mineType
      });
      saveAs(blob, name.match(reg)[1]);
    }

    downloadCsvLoading.value = false;
  };

  const downloadCsvLoading = ref<boolean>(false);

  return {
    changeTab,
    addCount,
    summariesEffect,
    activeTabId,
    straregyTimeout,
    isLoadingViewingRateHeatmaps,
    isLoadingContentRatioHeatmaps,
    isLoadingViewingRateList,
    isLoadingContentRatioList,
    viewingRateSeriesData,
    contentRatioSeriesData,
    viewingRateItems,
    contentRatioItems,
    viewingRateItemCount,
    contentRatioItemCount,
    stationNames,
    DATA_LIST_LIMIT,
    downloadCsv,
    downloadCsvLoading
  };
};
