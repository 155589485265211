import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chart-loader"
}
const _hoisted_2 = {
  key: 1,
  class: "chart-loader"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = {
  key: 0,
  class: "chart-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryInfo = _resolveComponent("SummaryInfo")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_LoadingMessage = _resolveComponent("LoadingMessage")!
  const _component_TimeoutScreen = _resolveComponent("TimeoutScreen")!
  const _component_ViewsColum = _resolveComponent("ViewsColum")!
  const _component_SummaryCard = _resolveComponent("SummaryCard")!

  return (_openBlock(), _createBlock(_component_SummaryCard, {
    id: "views-graph",
    title: "視聴回数"
  }, {
    "title-end": _withCtx(() => [
      _createVNode(_component_Flex, null, {
        default: _withCtx(() => [
          (typeof _ctx.summary?.numberOfTargetViews !== 'undefined')
            ? (_openBlock(), _createBlock(_component_SummaryInfo, {
                key: 0,
                label: "ターゲット<br>視聴回数",
                value: _ctx.resolveNumber(_ctx.summary.numberOfTargetViews),
                unit: _ctx.resolveUnit(_ctx.summary.numberOfTargetViews),
                loading: _ctx.initialLoading
              }, null, 8, ["value", "unit", "loading"]))
            : _createCommentVNode("", true),
          (typeof _ctx.summary?.numberOfTotalViews !== 'undefined')
            ? (_openBlock(), _createBlock(_component_SummaryInfo, {
                key: 1,
                label: "延べ視聴<br>回数",
                value: _ctx.resolveNumber(_ctx.summary.numberOfTotalViews),
                unit: _ctx.resolveUnit(_ctx.summary.numberOfTotalViews),
                loading: _ctx.initialLoading,
                class: "ml-4"
              }, null, 8, ["value", "unit", "loading"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _withDirectives(_createVNode(_component_Tabs, {
        "tab-list": _ctx.tabList,
        onChange: _ctx.changeTab,
        class: "mb-6"
      }, null, 8, ["tab-list", "onChange"]), [
        [_vShow, !_ctx.initialLoading && !_ctx.viewsTimeOut]
      ]),
      (_ctx.initialLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_LoadingMessage)
          ]))
        : (_ctx.viewsTimeOut)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_TimeoutScreen, {
                onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.summariesEffect([_ctx.project, _ctx.area, _ctx.date, _ctx.numOfEffectiveContacts])))
              })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabListData, (data) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: data.id
                }, [
                  (data.isLoading && _ctx.activeTabId === data.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_LoadingMessage)
                      ]))
                    : (_ctx.activeTabId === data.id)
                      ? (_openBlock(), _createBlock(_component_ViewsColum, {
                          key: _ctx.activeTabId,
                          data: data.graphData,
                          name: 
            _ctx.activeTabId === _ctx.ViewListIds.target
              ? 'ターゲット視聴回数'
              : '延べ視聴回数'
          
                        }, null, 8, ["data", "name"]))
                      : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
    ]),
    _: 1
  }))
}