
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Typography from '@/components/ui/Typography.vue';
import Button from '@/components/ui/Button.vue';
import TextField from '@/components/ui/TextField.vue';
import {
  useFrequencyThresholds,
  useValidator
} from '@/composables/campaign/brand_lift/frequency';
// import VeeTextField from '@/components/veeValidate/VeeTextField.vue';

export default defineComponent({
  name: 'FrequencyEditableLegend',
  components: {
    Flex,
    Button,
    TextField,
    Typography
    // VeeTextField
  },
  props: {
    optionsColors: {
      type: Array,
      required: true
    }
  },
  setup() {
    const { validNumber } = useValidator();

    return {
      ...useFrequencyThresholds(),
      validNumber
    };
  }
});
