
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { roundNumber } from '@/common/formatter';
import { useBrandLiftStore } from '@/store/brandLift';
import {
  useTargetRate,
  useComparision
} from '@/composables/campaign/brand_lift/targetRate';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import TimeoutScreen from '@/pages/campaign/brandlift/TimeoutScreen.vue';
import SummaryCard from '@/pages/campaign/brandlift/SummaryCard.vue';
import SummaryInfo from '@/pages/campaign/brandlift/SummaryInfo.vue';
import TargetRateDonut from '@/pages/campaign/brandlift/TargetRateDonut.vue';
import HeatmapByStation from '@/pages/campaign/brandlift/HeatmapByStation.vue';
import Flex from '@/components/layout/Flex.vue';
import Select from '@/components/ui/Select.vue';
import Typography from '@/components/ui/Typography.vue';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'TargetRate',
  components: {
    Flex,
    LoadingMessage,
    SummaryCard,
    SummaryInfo,
    Select,
    TimeoutScreen,
    TargetRateDonut,
    HeatmapByStation,
    Typography,
    Button
  },
  setup() {
    const route = useRoute();
    const projectId = Number(route.params.projectId);
    const {
      changeCompareHeatmap,
      resetCompareHeatmap,
      heatMapSeriesData,
      compareHeatMapSeriesData,
      cmChartData,
      stationNames,
      isLoadingHeatmaps,
      isLoadingCompareHeatmaps,
      targetRateTimout,
      summariesEffect,
      downloadCsv,
      downloadCsvLoading
    } = useTargetRate(projectId);

    const {
      comparision,
      pastComparision,
      productComparison,
      inhouseComparison,
      resetComparisions,
      pastComparisionOptions,
      productComparisionOptions,
      inhouseComparisionOptions
    } = useComparision(projectId);

    const store = useBrandLiftStore();
    const {
      summary,
      project,
      area,
      date,
      numOfEffectiveContacts
    } = storeToRefs(store);

    return {
      heatMapSeriesData,
      compareHeatMapSeriesData,
      cmChartData,
      stationNames,
      comparision,
      pastComparision,
      productComparison,
      inhouseComparison,
      resetComparisions,
      changeCompareHeatmap,
      resetCompareHeatmap,
      pastComparisionOptions,
      productComparisionOptions,
      inhouseComparisionOptions,
      isLoadingHeatmaps,
      isLoadingCompareHeatmaps,
      targetRateTimout,
      summary,
      project,
      area,
      date,
      numOfEffectiveContacts,
      roundNumber,
      summariesEffect,
      downloadCsv,
      downloadCsvLoading
    };
  }
});
