
import { defineComponent } from 'vue';
import Flex from '@/components/layout/Flex.vue';
import Typography from '@/components/ui/Typography.vue';
import ThreeDotsSvg from '@/assets/images/three-dots.svg?inline';

export default defineComponent({
  name: 'SummaryInfo',
  components: { Flex, Typography, ThreeDotsSvg },
  props: {
    label: String,
    value: [String, Number],
    unit: String,
    loading: Boolean
  }
});
