
import { defineComponent, computed, watch } from 'vue';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import Flex from '@/components/layout/Flex.vue';
import Box from '@/components/layout/Box.vue';
import Card from '@/components/ui/Card.vue';
import Icon from '@/components/ui/Icon.vue';
import IconButton from '@/components/ui/IconButton.vue';
import DatePicker from '@/components/ui/DatePicker.vue';
import Select from '@/components/ui/Select.vue';
import Button from '@/components/ui/Button.vue';
import Typography from '@/components/ui/Typography.vue';
import { useSummary } from '@/composables/campaign/brand_lift/analysis';
import { useRoute } from 'vue-router';
import { roundNumber, resolveNumber, resolveUnit } from '@/common/formatter';
import { useCmCreativeModal } from '@/composables/campaign/cmCreative';
import Modal from '@/components/ui/Modal.vue';
import DataList from '@/components/ui/DataList.vue';

export default defineComponent({
  name: 'brand-lift-summary',
  components: {
    Modal,
    Button,
    Box,
    Card,
    DatePicker,
    Flex,
    Icon,
    IconButton,
    LoadingMessage,
    Select,
    Typography,
    DataList
  },
  emits: ['change-param'],
  setup(_, { emit }) {
    const route = useRoute();
    const projectId = route.params['projectId'].toString();
    const {
      summary,
      project,
      fetch,
      isConfirmed,
      today,
      date,
      numOfEffectiveContacts,
      area,
      fetching,
      targetType,
      target,
      durationError,
      isChangeCondition,
      areaLabel,
      cmBuyingKindLabel
    } = useSummary(projectId);
    fetch(true);
    const isNotSetTotalPlacementCost = computed(() => {
      return (
        project.value?.targetSettings?.totalPlacementCost === undefined ||
        project.value.targetSettings.totalPlacementCost === 0
      );
    });

    // パラメータの変更があった場合、再度カスタムターゲット(購買ログ)の有効/無効をチェックするため、
    // 親コンポーネントに知らせる。
    watch([date, numOfEffectiveContacts, area], () => emit('change-param'));

    const {
      cmCreatives,
      isCmModalOpen,
      openCmModal,
      cmHeaders
    } = useCmCreativeModal();

    return {
      cmCreatives,
      isCmModalOpen,
      openCmModal,
      cmHeaders,
      summary,
      project,
      isConfirmed,
      today,
      area,
      date,
      numOfEffectiveContacts,
      targetType,
      target,
      fetching,
      durationError,
      roundNumber,
      resolveUnit,
      resolveNumber,
      isChangeCondition,
      isNotSetTotalPlacementCost,
      areaLabel,
      cmBuyingKindLabel
    };
  }
});
