
import { defineComponent, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CAMPAIGN_ROUTES, COMPANY_ROUTES, CUSTOM_ROUTES } from '@/router';
import { fetchProject } from '@/composables/campaign/brand_lift/list';
import { useBrandLiftStore } from '@/store/brandLift';
import Button from '@/components/ui/Button.vue';
import Modal from '@/components/ui/Modal.vue';
import Flex from '@/components/layout/Flex.vue';
import Strategy from '@/pages/campaign/brandlift/Strategy.vue';
import Summary from '@/pages/campaign/brandlift/Summary.vue';
import EffectiveReach from '@/pages/campaign/brandlift/EffectiveReach.vue';
import PlacementAmount from '@/pages/campaign/brandlift/PlacementAmount.vue';
import TargetRate from '@/pages/campaign/brandlift/TargetRate.vue';
import Typography from '@/components/ui/Typography.vue';
import Views from '@/pages/campaign/brandlift/Views.vue';
import LayoutWithSidebar from '@/components/layout/LayoutWithSidebar.vue';
import Frequency from '@/pages/campaign/brandlift/Frequency.vue';
import { toast } from '@/components/ui/Toast';

export default defineComponent({
  name: 'CampaignIndex',
  components: {
    LayoutWithSidebar,
    Button,
    Modal,
    Flex,
    Strategy,
    Summary,
    EffectiveReach,
    PlacementAmount,
    TargetRate,
    Views,
    Frequency,
    Typography
  },
  setup() {
    const store = useBrandLiftStore();

    const {
      params: { projectId, companyId }
    } = useRoute();
    const breadcrumbs = {
      parents: [
        { name: COMPANY_ROUTES.top, label: 'ホーム' },
        { name: '', label: 'エフェクティブリーチ' },
        { name: CAMPAIGN_ROUTES.index, label: 'リーチ分析 (Classic)' }
      ],
      current: { label: '' }
    };

    /**
     * setTitleprojectName
     * HTML meta titleにProject名を追加
     * Initは2回実行されるので、単独で実行する
     * TODO：vue3対応のmetaライブラリあれば入れ替え
     * @param name
     */
    (async () => {
      if (projectId) {
        const project = await fetchProject(Number(projectId));
        if (project) {
          const name = project.basicInfo.projectName;
          const { title } = document;
          document.title = name + ' | ' + title;
          breadcrumbs.current.label = name;
        }
      }
    })();

    onUnmounted(() => {
      store.resetState();
    });

    const router = useRouter();
    const goToCustomEdit = (customTargetId: number) => {
      router.push({
        name: CUSTOM_ROUTES.edit,
        params: { id: customTargetId, companyId }
      });
    };
    const goToProjectEdit = () => {
      router.push({
        name: CAMPAIGN_ROUTES.edit,
        params: {
          companyId: companyId,
          projectId: projectId
        }
      });
    };

    const targetIsEnabled = ref<boolean>(false);
    const isShowModal = ref<boolean>(false);
    const customTargetName = ref('');
    const customTargetId = ref(0);

    const init = async () => {
      if (projectId) {
        const judgeProject = await fetchProject(Number(projectId));
        targetIsEnabled.value =
          judgeProject?.targetSettings.targetIsEnabled ?? false;
        if (!targetIsEnabled.value) {
          toast({
            variant: 'error',
            title: '使用できないオプションが使用されています',
            message: 'カスタムターゲットかエリアをご確認ください'
          });
          await router.push({
            name: CAMPAIGN_ROUTES.index,
            params: { companyId }
          });
          // isShowModal.value = true;
          // // targetIsEnabledがfalseになるケースが存在するのはカスタムターゲットのみ
          // const customTargetData = judgeProject?.targetSettings.targets.shift();
          // customTargetName.value = customTargetData?.targetName ?? '';
          // customTargetId.value = customTargetData?.targetId ?? 0;
        }
      }
    };

    init();

    return {
      store,
      breadcrumbs,
      projectId,
      init,
      goToCustomEdit,
      goToProjectEdit,
      targetIsEnabled,
      isShowModal,
      customTargetName,
      customTargetId
    };
  }
});
