import { ProjectApi, ReachAnalysisApi } from '@/api';
import { ReachAnalysisProject, TargetRateHeatMap } from '@/api/openapi';
import { httpCode } from '@/common/constant';
import { DATE_FORMAT } from '@/common/format';
import { validDuration } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { useBrandLiftStore } from '@/store/brandLift';
import Axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import saveAs from 'file-saver';
import { storeToRefs } from 'pinia';
import { Ref, ref, watch } from 'vue';
import { useStationColor } from './colors';

const today = new Date();

type SeriesData = {
  x: number;
  y: number;
  cm: string;
  value: number;
  font: string;
};

type ChartData = {
  name: string;
  y: number;
  color: string;
};

interface useTargetRateReturnType {
  changeCompareHeatmap: (params: {
    productIdOfProductCompare?: number;
    startDate?: string;
    endDate?: string;
  }) => Promise<void>;
  summariesEffect: (
    params: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => void;
  resetCompareHeatmap: () => void;
  isLoadingHeatmaps: Ref<boolean>;
  isLoadingCompareHeatmaps: Ref<boolean>;
  targetRateTimout: Ref<boolean>;
  heatMapSeriesData: Ref<SeriesData[]>;
  compareHeatMapSeriesData: Ref<SeriesData[]>;
  cmChartData: Ref<ChartData[]>;
  stationNames: Ref<{ label: string; color: string }[]>;
  downloadCsv: (isCompare: boolean) => Promise<void>;
  downloadCsvLoading: Ref<boolean>;
}

export const useTargetRate = (
  reachAnalysisProjectId: number
): useTargetRateReturnType => {
  const DIVIDE_NUM = 5;
  const store = useBrandLiftStore();
  const { project, area, date, numOfEffectiveContacts } = storeToRefs(store);
  const { getStationColorByCode } = useStationColor();
  const heatMapSeriesData = ref<SeriesData[]>([]);
  const compareHeatMapSeriesData = ref<SeriesData[]>([]);
  const stationNames = ref<{ label: string; color: string }[]>([]);
  const cmChartData = ref<ChartData[]>([]);
  const isLoadingHeatmaps = ref(false);
  const isLoadingCompareHeatmaps = ref(false);
  const targetRateTimout = ref(false);
  const currentCompareStartDate = ref<string | undefined>();
  const currentCompareEndDate = ref<string | undefined>();
  const currentProductIdOfProductCompare = ref<number | undefined>();
  const currentCompareType = ref<
    'PAST' | 'COMPETITIVE' | 'OWN_COMPANY' | undefined
  >();

  const setHeatMapSeriesData = (heatmaps?: TargetRateHeatMap[]) => {
    const values = heatmaps?.flatMap(heatmap =>
      heatmap.heatMapByDayOfWeek?.flatMap(week =>
        week.heatMapCells?.map(cell => cell.targetRate)
      )
    ) as Array<number>;
    const max = Math.max(...values);
    const min = Math.min(...values);
    const level = (max - min) / DIVIDE_NUM;
    if (!heatmaps) return [];
    let seriesData: SeriesData[] = [];
    for (const heatmap of heatmaps) {
      if (heatmap.heatMapByDayOfWeek) {
        const stationIndex = heatmaps.findIndex(
          _heatmap => _heatmap.stationName === heatmap.stationName
        );
        for (const day of heatmap.heatMapByDayOfWeek) {
          if (day.heatMapCells) {
            const dayIndex =
              heatmap.heatMapByDayOfWeek?.findIndex(
                _day => _day.dayOfWeek === day.dayOfWeek
              ) ?? 0;
            const cells = day.heatMapCells.map(cell => ({
              x: dayIndex + stationIndex * 7,
              y: cell.broadcastTimezone ? cell.broadcastTimezone - 5 : 0,
              cm:
                cell.numberOfCmPlacement && cell.numberOfCmPlacement > 0
                  ? cell.numberOfCmPlacement.toString()
                  : '', // 出稿数
              value: cell.targetRate ?? 0, // ターゲット比率
              font:
                cell.targetRate && cell.targetRate > min + level * 2
                  ? 'var(--contrast-color)'
                  : 'var(--primary-color)'
            }));
            seriesData = [...seriesData, ...cells];
          }
        }
      }
    }
    return seriesData;
  };

  // ターゲット比率が100を超える場合は100に丸める
  const convertHeatMap = (
    heatmaps?: TargetRateHeatMap[]
  ): TargetRateHeatMap[] | undefined => {
    if (!heatmaps) return heatmaps;
    return heatmaps.map(heatmap => ({
      ...heatmap,
      heatMapByDayOfWeek: heatmap.heatMapByDayOfWeek?.map(week => ({
        ...week,
        heatMapCells: week.heatMapCells?.map(cell => ({
          ...cell,
          targetRate: cell.targetRate < 100.0 ? cell.targetRate : 100.0
        }))
      }))
    })) as TargetRateHeatMap[];
  };

  const setCmChartData = (heatmaps?: TargetRateHeatMap[]) => {
    return (
      heatmaps?.map(heatmap => ({
        name: heatmap.stationName ?? '',
        y: heatmap.numOfCmPlacement ?? 0,
        color: getStationColorByCode(heatmap.stationCode)
      })) || []
    );
  };

  const fetchTargetRate = async ({
    startDate,
    endDate,
    productIdOfProductCompare,
    compareType
  }: {
    startDate: string;
    endDate: string;
    productIdOfProductCompare?: number;
    compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY';
  }) => {
    const effectiveNumberOfViews = Number(numOfEffectiveContacts.value);
    const areaCode = area.value;
    if (effectiveNumberOfViews && startDate && endDate && areaCode) {
      return await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdTargetRate(
        reachAnalysisProjectId,
        effectiveNumberOfViews,
        startDate,
        endDate,
        areaCode,
        productIdOfProductCompare,
        compareType
      );
    }
  };

  const fetchCompareHeatmap = async ({
    startDate,
    endDate,
    productIdOfProductCompare,
    compareType
  }: {
    startDate?: string;
    endDate?: string;
    productIdOfProductCompare?: number;
    compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY';
  }) => {
    isLoadingCompareHeatmaps.value = true;
    const result = await fetchTargetRate({
      startDate: startDate || format(date.value?.start || today, DATE_FORMAT),
      endDate: endDate || format(date.value?.end || today, DATE_FORMAT),
      productIdOfProductCompare,
      compareType
    });
    isLoadingCompareHeatmaps.value = false;
    if (!result) return;
    const heatMaps = convertHeatMap(result.data.heatMaps);
    compareHeatMapSeriesData.value = setHeatMapSeriesData(heatMaps);
    currentCompareStartDate.value = startDate;
    currentCompareEndDate.value = endDate;
    currentProductIdOfProductCompare.value = productIdOfProductCompare;
    currentCompareType.value = compareType;
  };

  const changeCompareHeatmap = async (params: {
    startDate?: string;
    endDate?: string;
    productIdOfProductCompare?: number;
    compareType?: 'PAST' | 'COMPETITIVE' | 'OWN_COMPANY';
  }) => {
    await fetchCompareHeatmap(params);
  };

  const resetCompareHeatmap = () => {
    compareHeatMapSeriesData.value = [];
    currentCompareStartDate.value = undefined;
    currentCompareEndDate.value = undefined;
    currentProductIdOfProductCompare.value = undefined;
    currentCompareType.value = undefined;
  };

  // サマリー指標が更新されたらグラフを更新する
  const summariesEffect = async (
    values: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => {
    const [project, area, date, numOfEffectiveContacts] = values;
    if (
      project &&
      area &&
      date &&
      numOfEffectiveContacts &&
      validDuration(date)
    ) {
      isLoadingHeatmaps.value = true;
      targetRateTimout.value = false;
      let result;
      try {
        result = await fetchTargetRate({
          startDate: format(date.start || today, DATE_FORMAT),
          endDate: format(date.end || today, DATE_FORMAT)
        });
      } catch (e) {
        if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
          targetRateTimout.value = true;
        }
      }

      isLoadingHeatmaps.value = false;
      if (!result) return;
      const heatMaps = convertHeatMap(result.data.heatMaps);
      heatMapSeriesData.value = setHeatMapSeriesData(heatMaps);
      cmChartData.value = setCmChartData(heatMaps);

      const _stationNames = heatMaps
        ?.map(heatmap => ({
          label: heatmap.stationName ?? '',
          color: getStationColorByCode(heatmap.stationCode)
        }))
        .filter(station => !!station.label);
      if (_stationNames?.length) {
        stationNames.value = _stationNames;
      }

      // 競合 | 自社商品グラフが表示されていたら更新
      if (currentProductIdOfProductCompare.value || currentCompareType.value) {
        await fetchCompareHeatmap({
          startDate: currentCompareStartDate.value,
          endDate: currentCompareEndDate.value,
          productIdOfProductCompare: currentProductIdOfProductCompare.value,
          compareType: currentCompareType.value
        });
      }
    }
  };

  watch([project, area, date, numOfEffectiveContacts], summariesEffect);

  // CSVデータのターゲット比率が100を超える場合は100に丸める
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
  const convertCsv = (csvData: any) => {
    const splitCsvData = csvData.replace(/\r?\n/g, '\n').split(',');

    let startIndex = splitCsvData.findIndex(
      val => val.includes('ターゲット比率') && !val.includes('リーチ分析')
    );
    startIndex = startIndex !== -1 ? startIndex : 0;
    let endIndex = splitCsvData.findIndex(val => val.includes('出稿本数'));
    endIndex = endIndex !== -1 ? endIndex : splitCsvData.length;
    return splitCsvData.map((cell, i) => {
      let rateValue = '';
      let time = '';
      if (cell.indexOf('\n') != -1) {
        // xx\nhh:mm 形式の場合
        rateValue = cell.split('\n')[0];
        time = '\n' + cell.split('\n')[1];
      } else {
        rateValue = cell;
      }
      if (startIndex < i && i <= endIndex && parseFloat(rateValue) > 100.0) {
        return '100.0' + time;
      } else {
        return cell;
      }
    });
  };

  const downloadFile = (res: AxiosResponse) => {
    const convertData = convertCsv(res.data);
    const mineType = res.headers['content-type'];
    const name = res.headers['content-disposition'];
    const reg = RegExp(/filename=(.*)/);
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), convertData], {
      type: mineType
    });
    saveAs(blob, name.match(reg)[1]);
  };

  const downloadCsv = async (isCompare: boolean) => {
    downloadCsvLoading.value = true;

    const effectiveNumberOfViews = Number(numOfEffectiveContacts.value);
    const areaCode = area.value;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);

    if (effectiveNumberOfViews && areaCode && startData && endData) {
      if (isCompare) {
        // 比較追加の場合
        if (currentCompareType.value === 'PAST') {
          if (currentCompareStartDate.value && currentCompareEndDate.value) {
            const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(
              reachAnalysisProjectId,
              effectiveNumberOfViews,
              currentCompareStartDate.value,
              currentCompareEndDate.value,
              areaCode,
              undefined,
              currentCompareType.value
            );
            downloadFile(res);
          }
        } else {
          const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(
            reachAnalysisProjectId,
            effectiveNumberOfViews,
            startData,
            endData,
            areaCode,
            currentProductIdOfProductCompare.value,
            currentCompareType.value
          );
          downloadFile(res);
        }
      } else {
        // 商品自身の場合
        const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdTargetRateCsv(
          reachAnalysisProjectId,
          effectiveNumberOfViews,
          startData,
          endData,
          areaCode
        );
        downloadFile(res);
      }
    }

    downloadCsvLoading.value = false;
  };

  const downloadCsvLoading = ref<boolean>(false);

  return {
    changeCompareHeatmap,
    resetCompareHeatmap,
    summariesEffect,
    isLoadingHeatmaps,
    isLoadingCompareHeatmaps,
    targetRateTimout,
    heatMapSeriesData,
    compareHeatMapSeriesData,
    cmChartData,
    stationNames,
    downloadCsv,
    downloadCsvLoading
  };
};

type Option = { id: number; label: string };
interface UseComparisonReturnType {
  resetComparisions: (projectId: string) => void;
  comparision: Ref<Option | null>;
  pastComparision: Ref<Option | null>;
  productComparison: Ref<Option | null>;
  inhouseComparison: Ref<Option | null>;
  productComparisionOptions: Ref<Option[]>;
  pastComparisionOptions: Ref<Option[]>;
  inhouseComparisionOptions: Ref<Option[]>;
}
export const useComparision = (
  reachAnalysisProjectId: number
): UseComparisonReturnType => {
  const comparision = ref<Option | null>(null);
  const pastComparision = ref<Option | null>(null);
  const productComparison = ref<Option | null>(null);
  const inhouseComparison = ref<Option | null>(null);
  const pastComparisionOptions = ref<Option[]>([]);
  const productComparisionOptions = ref<Option[]>([]);
  const inhouseComparisionOptions = ref<Option[]>([]);
  const store = useBrandLiftStore();
  const { project } = storeToRefs(store);

  const projectEffect = async (project?: ReachAnalysisProject) => {
    pastComparisionOptions.value =
      project?.comparisonSettings?.pastComparisons?.map((past, index) => ({
        id: past.pastComparisonId ?? index,
        label: `${past.startDate}〜${past.endDate}`
      })) || [];
    if (
      productComparisionOptions.value.length === 0 ||
      inhouseComparisionOptions.value.length === 0
    ) {
      const result = await ProjectApi.getReachAnalysisProject(
        reachAnalysisProjectId
      );
      const comparisonSettings = result.data.comparisonSettings;
      if (!comparisonSettings) return;
      productComparisionOptions.value =
        comparisonSettings.productComparisonProducts?.map((product, index) => ({
          id: product.productId ?? index,
          label: product.productName ?? ''
        })) || [];
      inhouseComparisionOptions.value =
        comparisonSettings.inhouseComparisonProducts?.map((inhouse, index) => ({
          id: inhouse.productId ?? index,
          label: inhouse.productName ?? ''
        })) || [];
    }
  };
  watch(project, projectEffect);

  const resetComparisions = () => {
    pastComparision.value = null;
    productComparison.value = null;
    inhouseComparison.value = null;
  };

  return {
    comparision,
    pastComparision,
    productComparison,
    inhouseComparison,
    pastComparisionOptions,
    productComparisionOptions,
    inhouseComparisionOptions,
    resetComparisions
  };
};
