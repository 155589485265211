import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "summary-info" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_ThreeDotsSvg = _resolveComponent("ThreeDotsSvg")!
  const _component_Flex = _resolveComponent("Flex")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Flex, { "align-center": "" }, {
      default: _withCtx(() => [
        _createVNode(_component_Typography, {
          "line-height": "1.2",
          size: "xs",
          color: "grey"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", { innerHTML: _ctx.label }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_Flex, {
              key: 0,
              class: "loading ml-1",
              "justify-center": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ThreeDotsSvg, { style: { width: 20, height: 4 } })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.loading && typeof _ctx.value !== 'undefined')
          ? (_openBlock(), _createBlock(_component_Flex, {
              key: 1,
              "align-baseline": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Typography, {
                  size: "xxxl",
                  color: "primary",
                  class: "ml-1",
                  bold: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.value), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Typography, {
                  size: "xs",
                  class: "ml-1",
                  bold: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.unit), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}