<template>
  <template v-if="false">
    <Flex justify-center class="pa-6">
      <Loading />
    </Flex>
  </template>
  <template v-else>
    <Chart :options="chartOptions" class="views-chart" />
  </template>
</template>
<script>
import { defineComponent, computed, ref } from 'vue';
import { Chart } from 'highcharts-vue';
import Flex from '@/components/layout/Flex.vue';
import Loading from '@/components/ui/Loading.vue';
import { resolveNumberWithUnit } from '@/common/formatter';

export default defineComponent({
  name: 'ViewsColum',
  components: {
    Chart,
    Flex,
    Loading
  },
  props: {
    data: Array,
    name: String
  },
  setup(props) {
    const chartOptions = ref({
      chart: {
        type: 'column',
        marginTop: 28,
        scrollablePlotArea: {
          minWidth: 945,
          scrollPositionX: 1
        }
      },
      title: {
        text: null
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          return resolveNumberWithUnit(this.y);
        }
      },
      xAxis: {
        categories: props.data.map(viewing => viewing.name)
      },
      yAxis: {
        title: {
          align: 'high',
          offset: 0,
          text: '(万回)',
          rotation: 0,
          x: -8,
          y: -16,
          style: {
            fontSize: 10
          }
        },
        labels: {
          formatter: function(label) {
            return label.value / 10000;
          }
        }
      },
      series: [
        {
          colorByPoint: true,
          name: computed(() => props.name),
          data: computed(() => props.data)
        }
      ],
      credits: {
        enabled: false
      },
      exporting: {
        buttons: undefined
      }
    });

    return {
      chartOptions
    };
  }
});
</script>
<style lang="scss" scoped>
.views-chart {
  max-width: 100vw;
}
</style>
