import { ReachAnalysisApi } from '@/api';
import { Grp, GrpForSov, ReachAnalysisProject } from '@/api/openapi';
import { DATE_FORMAT } from '@/common/format';
import { round } from '@/common/formatter';
import { validDuration } from '@/common/validation';
import { DateRange } from '@/components/ui/DatePicker.vue';
import { useCompareColors } from '@/composables/campaign/brand_lift/colors';
import { useTabs } from '@/composables/tabs';
import { useBrandLiftStore } from '@/store/brandLift';
import { format } from 'date-fns';
import { storeToRefs } from 'pinia';
import { Ref, ref, watch } from 'vue';
import Axios, { AxiosResponse } from 'axios';
import { httpCode } from '@/common/constant';
import saveAs from 'file-saver';

// APIが変更になった場合を考慮してフロント用の構造を定義
interface PlacementAmountGraphDataType {
  day?: {
    displayName?: string;
    numbersOfGrp?: {
      date?: string;
      grp?: number;
    }[];
    color: string;
  }[];
  accumulation?: {
    displayName?: string;
    numbersOfGrp?: {
      date?: string;
      grp?: number;
    }[];
    color: string;
  }[];
}

interface PlacementAmountGraphSovDataType {
  grpsForSov?: {
    stDate?: string;
    edDate?: string;
    totalGrp?: number;
    productShare?: {
      productId?: number;
      productName?: string;
      grp?: number;
      share?: number;
      color: string;
    }[];
  }[];
}

interface UsePlacementAmountReturnType {
  changeTab: (tabId: string) => Promise<void>;
  changeParameter: (
    params: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => void;
  activeTabId: Ref<string>;
  pastData: Ref<PlacementAmountGraphDataType | undefined>;
  competitiveData: Ref<PlacementAmountGraphDataType | undefined>;
  ownCompanyData: Ref<PlacementAmountGraphDataType | undefined>;
  sovData: Ref<PlacementAmountGraphSovDataType | undefined>;
  accumulation: Ref<string>;
  pastIsLoading: Ref<boolean>;
  competitiveIsLoading: Ref<boolean>;
  ownCompanyIsLoading: Ref<boolean>;
  sovIsLoading: Ref<boolean>;
  initialLoading: Ref<boolean>;
  placementAmountTimeOut: Ref<boolean>;
  downloadCsv: (tabId: string) => Promise<void>;
  downloadCsvLoading: Ref<boolean>;
}

export const usePlacementAmount = (
  reachAnalysisProjectId: number,
  tabList: { id: string; name: string }[]
): UsePlacementAmountReturnType => {
  const innerTabId = ref<string>(tabList[0].id);
  const { activeTabId, changeTab: _changeTab } = useTabs(tabList[0].id);
  const store = useBrandLiftStore();
  const { project, area, date, numOfEffectiveContacts } = storeToRefs(store);
  const pastData = ref<PlacementAmountGraphDataType | undefined>(undefined);
  const competitiveData = ref<PlacementAmountGraphDataType | undefined>(
    undefined
  );
  const ownCompanyData = ref<PlacementAmountGraphDataType | undefined>(
    undefined
  );
  const sovData = ref<PlacementAmountGraphSovDataType | undefined>(undefined);
  const accumulation = ref<string>('true');

  const pastIsLoading = ref<boolean>(false);
  const competitiveIsLoading = ref<boolean>(false);
  const ownCompanyIsLoading = ref<boolean>(false);
  const sovIsLoading = ref<boolean>(false);
  const initialLoading = ref<boolean>(false);
  const placementAmountTimeOut = ref<boolean>(false);

  const { getCompareColorsByType } = useCompareColors();

  const setData = (response: Grp | undefined) => {
    const colors = getCompareColorsByType(innerTabId.value);
    return {
      day: response?.grpByDay?.map((e, i) => ({
        displayName: e.displayName,
        numbersOfGrp: e.numbersOfGrp?.map(d => ({
          date: d.date,
          grp: d.grp
        })),
        color: colors[i]
      })),
      accumulation: response?.grpAccumulationPerDay?.map((e, i) => ({
        displayName: e.displayName,
        numbersOfGrp: e.numbersOfGrp?.map(d => ({
          date: d.date,
          grp: d.grp
        })),
        color: colors[i]
      }))
    };
  };

  const setSovData = (response: GrpForSov | undefined) => {
    const colors = getCompareColorsByType('competitive');
    return {
      grpsForSov: response?.grpsForSov?.map(e => ({
        stDate: e.stDate,
        edDate: e.edDate,
        totalGrp: e.totalGrp,
        productShare: e.productShare?.map((d, i) => ({
          productId: d.productId,
          productName: d.productName,
          grp: d.grp,
          share:
            d.share !== undefined
              ? //小数点以下が３桁以上ある場合、少数第2桁で四捨五入する
                (String(d.share).split('.')[1]?.length >= 3
                  ? round(d.share, 4)
                  : d.share) * 100
              : undefined,
          color: colors[i]
        }))
      }))
    };
  };

  const fetch = async (tabId: string) => {
    if (
      (tabList[0].id === tabId && pastData.value) ||
      (tabList[1].id === tabId && competitiveData.value) ||
      (tabList[2].id === tabId && sovData.value) ||
      (tabList[3].id === tabId && ownCompanyData.value)
    ) {
      return;
    }

    const today = new Date();
    const effectiveNumberOfViews = numOfEffectiveContacts.value
      ? parseInt(numOfEffectiveContacts.value, 10)
      : undefined;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);
    if (effectiveNumberOfViews) {
      if (tabList[2].id !== tabId) {
        const compareType = (function(id) {
          if (tabList[1].id === id) {
            competitiveIsLoading.value = true;
            return 'COMPETITIVE';
          }
          if (tabList[3].id === id) {
            ownCompanyIsLoading.value = true;
            return 'OWN_COMPANY';
          }
          pastIsLoading.value = true;
          return 'PAST';
        })(tabId);
        if (area.value) {
          const result = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmount(
            reachAnalysisProjectId,
            effectiveNumberOfViews,
            startData,
            endData,
            area.value,
            compareType
          );
          if (tabList[0].id === tabId) {
            pastData.value = setData(result?.data);
            pastIsLoading.value = false;
          } else if (tabList[1].id === tabId) {
            competitiveData.value = setData(result?.data);
            competitiveIsLoading.value = false;
          } else if (tabList[3].id === tabId) {
            ownCompanyData.value = setData(result?.data);
            ownCompanyIsLoading.value = false;
          }
        }
      } else {
        sovIsLoading.value = true;
        if (area.value) {
          const result = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSov(
            reachAnalysisProjectId,
            effectiveNumberOfViews,
            startData,
            endData,
            area.value
          );
          sovData.value = setSovData(result?.data);
          sovIsLoading.value = false;
        }
      }
    }
  };

  const changeTab = async (tabId: string) => {
    innerTabId.value = tabId;
    _changeTab(tabId);
    try {
      await fetch(innerTabId.value);
      placementAmountTimeOut.value = false;
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
        placementAmountTimeOut.value = true;
      }
    }
  };

  const changeParameter = async (
    values: [
      ReachAnalysisProject | undefined,
      string | undefined,
      DateRange | undefined,
      string | undefined
    ]
  ) => {
    const [project, area] = values;
    if (
      project &&
      area &&
      date.value &&
      numOfEffectiveContacts.value &&
      validDuration(date.value)
    ) {
      pastData.value = undefined;
      competitiveData.value = undefined;
      ownCompanyData.value = undefined;
      sovData.value = undefined;

      initialLoading.value = true;
      placementAmountTimeOut.value = false;
      try {
        await fetch(innerTabId.value);
      } catch (e) {
        if (Axios.isAxiosError(e) && e.response?.status === httpCode.timeout) {
          placementAmountTimeOut.value = true;
        }
      }
      initialLoading.value = false;
    }
  };
  watch([project, area, date, numOfEffectiveContacts], changeParameter);

  const downloadFile = (res: AxiosResponse) => {
    const mineType = res.headers['content-type'];
    const name = res.headers['content-disposition'];
    const reg = RegExp(/filename=(.*)/);
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), res.data], {
      type: mineType
    });
    saveAs(blob, name.match(reg)[1]);
  };

  const downloadCsv = async (tabId: string) => {
    downloadCsvLoading.value = true;

    const today = new Date();
    const effectiveNumberOfViews = numOfEffectiveContacts.value
      ? parseInt(numOfEffectiveContacts.value, 10)
      : undefined;
    const startData = format(date.value?.start || today, DATE_FORMAT);
    const endData = format(date.value?.end || today, DATE_FORMAT);

    if (effectiveNumberOfViews && area.value) {
      if (tabList[2].id === tabId) {
        // SOVの場合
        const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountSovCsv(
          reachAnalysisProjectId,
          effectiveNumberOfViews,
          startData,
          endData,
          area.value
        );
        downloadFile(res);
      } else {
        // SOV以外の場合
        const compareType =
          tabList[0].id === tabId
            ? 'PAST'
            : tabList[1].id === tabId
            ? 'COMPETITIVE'
            : 'OWN_COMPANY';
        const res = await ReachAnalysisApi.getReachAnalysisProjectsReachAnalysisProjectIdPlacementAmountCsv(
          reachAnalysisProjectId,
          effectiveNumberOfViews,
          startData,
          endData,
          area.value,
          compareType
        );
        downloadFile(res);
      }
    }

    downloadCsvLoading.value = false;
  };

  const downloadCsvLoading = ref<boolean>(false);

  return {
    changeTab,
    changeParameter,
    activeTabId,
    pastData,
    competitiveData,
    ownCompanyData,
    sovData,
    accumulation,
    pastIsLoading,
    competitiveIsLoading,
    ownCompanyIsLoading,
    sovIsLoading,
    initialLoading,
    placementAmountTimeOut,
    downloadCsv,
    downloadCsvLoading
  };
};
