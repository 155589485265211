
import { defineComponent, computed } from 'vue';
import { roundNumber } from '@/common/formatter';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useBrandLiftStore } from '@/store/brandLift';
import { usePlacementAmount } from '@/composables/campaign/brand_lift/placementAmount';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import TimeoutScreen from '@/pages/campaign/brandlift/TimeoutScreen.vue';
import PlacementAmountGraph from '@/pages/campaign/brandlift/PlacementAmountGraph.vue';
import PlacementAmountGraphSov from '@/pages/campaign/brandlift/PlacementAmountGraphSov.vue';
import SummaryCard from '@/pages/campaign/brandlift/SummaryCard.vue';
import SummaryInfo from '@/pages/campaign/brandlift/SummaryInfo.vue';
import Flex from '@/components/layout/Flex.vue';
import Tabs from '@/components/ui/Tabs.vue';
import RadioButtonGroup from '@/components/ui/RadioButtonGroup.vue';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'PlacementAmount',
  components: {
    Flex,
    LoadingMessage,
    SummaryCard,
    SummaryInfo,
    Tabs,
    RadioButtonGroup,
    PlacementAmountGraph,
    PlacementAmountGraphSov,
    TimeoutScreen,
    Button
  },
  setup() {
    const route = useRoute();
    const projectId = parseInt(route.params.projectId.toString());
    const store = useBrandLiftStore();
    const {
      summary,
      project,
      area,
      date,
      numOfEffectiveContacts
    } = storeToRefs(store);
    const tabList = computed(() => [
      { id: 'past', name: '過去比較' },
      {
        id: 'competitive',
        name: '競合比較',
        lock: !store.hasCompetitive,
        showLockIcon: false
      },
      {
        id: 'sov',
        name: 'SOV',
        lock: !store.hasCompetitive,
        showLockIcon: false
      },
      {
        id: 'own_company',
        name: '自社比較',
        lock: !store.hasOwnCompany,
        showLockIcon: false
      }
    ]);

    const {
      changeTab,
      changeParameter,
      activeTabId,
      pastData,
      competitiveData,
      ownCompanyData,
      sovData,
      accumulation,
      pastIsLoading,
      competitiveIsLoading,
      ownCompanyIsLoading,
      sovIsLoading,
      initialLoading,
      placementAmountTimeOut,
      downloadCsv,
      downloadCsvLoading
    } = usePlacementAmount(projectId, tabList.value);

    const graphLoading = computed(() => {
      switch (activeTabId.value) {
        case 'past':
          return pastIsLoading.value;
        case 'competitive':
          return competitiveIsLoading.value;
        case 'sov':
          return sovIsLoading.value;
        case 'own_company':
          return ownCompanyIsLoading.value;
        default:
          return false;
      }
    });

    return {
      tabList,
      summary,
      activeTabId,
      changeTab,
      project,
      area,
      date,
      numOfEffectiveContacts,
      pastData,
      competitiveData,
      ownCompanyData,
      sovData,
      accumulation,
      pastIsLoading,
      competitiveIsLoading,
      ownCompanyIsLoading,
      sovIsLoading,
      initialLoading,
      placementAmountTimeOut,
      changeParameter,
      roundNumber,
      downloadCsv,
      downloadCsvLoading,
      graphLoading
    };
  }
});
