import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Typography = _resolveComponent("Typography")!
  const _component_Flex = _resolveComponent("Flex")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, { class: "summary-card" }, {
    default: _withCtx(() => [
      _createVNode(_component_Flex, {
        class: "summary-card-title",
        "justify-space-between": "",
        "align-center": ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Typography, {
            size: "xl",
            bold: ""
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _renderSlot(_ctx.$slots, "title-end")
        ]),
        _: 3
      }),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}