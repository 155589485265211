import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_FrequencyEditableLegend = _resolveComponent("FrequencyEditableLegend")!
  const _component_Flex = _resolveComponent("Flex")!

  return (_openBlock(), _createBlock(_component_Flex, {
    "flex-direction": "column",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Chart, {
        options: _ctx.options,
        class: "views-chart"
      }, null, 8, ["options"]),
      (_ctx.graphType === 'ratio')
        ? (_openBlock(), _createBlock(_component_FrequencyEditableLegend, {
            key: 0,
            optionsColors: _ctx.options.colors
          }, null, 8, ["optionsColors"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}