
import { defineComponent, computed } from 'vue';
import { roundNumber } from '@/common/formatter';
import SummaryCard from '@/pages/campaign/brandlift/SummaryCard.vue';
import SummaryInfo from '@/pages/campaign/brandlift/SummaryInfo.vue';
import Flex from '@/components/layout/Flex.vue';
import Tabs from '@/components/ui/Tabs.vue';
import RadioButtonGroup from '@/components/ui/RadioButtonGroup.vue';
import LoadingMessage from '@/pages/campaign/brandlift/LoadingMessage.vue';
import { useFrequency } from '@/composables/campaign/brand_lift/frequency';
import { useBrandLiftStore } from '@/store/brandLift';
import { storeToRefs } from 'pinia/dist/pinia';
import FrequencyGraph from '@/pages/campaign/brandlift/FrequencyGraph.vue';
import { resetThresholds } from '@/composables/campaign/brand_lift/frequency';
import Button from '@/components/ui/Button.vue';

export default defineComponent({
  name: 'Frequency',
  components: {
    Button,
    SummaryCard,
    SummaryInfo,
    Flex,
    Tabs,
    RadioButtonGroup,
    LoadingMessage,
    FrequencyGraph
  },
  setup() {
    const store = useBrandLiftStore();
    const { summary } = storeToRefs(store);
    resetThresholds();
    const tabList = computed(() => [
      { id: 'PAST', name: '過去比較' },
      {
        id: 'COMPETITIVE',
        name: '競合比較',
        lock: !store.hasCompetitive,
        showLockIcon: false
      },
      {
        id: 'OWN_COMPANY',
        name: '自社比較',
        lock: !store.hasOwnCompany,
        showLockIcon: false
      }
    ]);

    return {
      ...useFrequency(),
      summary,
      tabList,
      roundNumber
    };
  }
});
